import type { Field } from '../Form/types'
import { Checkbox, Group } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useController, type Control, type FieldError } from 'react-hook-form'

interface CheckboxProps {
  field: Field
  fieldName: string
  control: Control
  renderLabel: (arg: Field) => JSX.Element
}

export const CheckBox = ({
  field,
  fieldName,
  control,
  renderLabel
}: CheckboxProps): JSX.Element => {
  const {
    field: controllerField,
    fieldState: { error }
  } = useController({
    name: fieldName,
    control,
    rules: { required: { value: field.required, message: 'Field is required' } }
  })

  const errorMessage = (error: FieldError | undefined | null): JSX.Element | null => {
    let errorComponent: JSX.Element | null | undefined = null
    if (error?.message !== null && error?.message !== undefined) {
      errorComponent = (
        <div style={{ marginTop: '5px', fontSize: '14px' }}>
          <FontAwesomeIcon icon={faCircleExclamation} color={'#d93025'} />
          <span style={{ color: '#d93025', fontWeight: 600, marginLeft: '5px' }}>
            {error.message}
          </span>
        </div>
      )
    }
    return errorComponent
  }

  return (
    <Checkbox.Group
      {...controllerField}
      label={renderLabel(field)}
      error={errorMessage(error)}
      withAsterisk={field.required}
    >
      <Group mt="xs">
        {field.options.map((option, i) => {
          return <Checkbox key={`${i}-${fieldName}`} label={option.label} value={option.value} />
        })}
      </Group>
    </Checkbox.Group>
  )
}
