import { useLayoutEffect } from 'react'

import { Box, useMantineTheme } from '@mantine/core'
import { Notifications } from '@mantine/notifications'

import { AppContainer } from 'components/app-container'
import { Footer } from 'components/Footer/footer'
import { HeaderResponsive } from 'components/Header/header'
import { Outlet } from 'react-router-dom'

export const Layout: React.FC = () => {
  const { other: { favicon: faviconUrl } = {} } = useMantineTheme()

  useLayoutEffect(() => {
    if (faviconUrl === undefined) return

    const faviconElement = document.getElementById('favicon')
    if (faviconElement === null) return

    faviconElement.setAttribute('href', faviconUrl)
  }, [faviconUrl])

  return (
    <>
      <Notifications position="top-right" />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <HeaderResponsive />

        <AppContainer>
          <Outlet />
        </AppContainer>
        <Footer links={[]} socialLinks={[]} />
      </Box>
    </>
  )
}
