import { basePlatformApiURL } from 'utils/UrlHelper'
import { type HexValue, type Customization } from 'types'
import { DEFAULT_COLORS, DEFAULT_LOGO_PATH, DEFAULT_TENANT_URL } from 'app-constants'

export interface MenuLink {
  label: string
  url: string
}

export interface CustomizationResponse {
  color_primary: HexValue
  color_secondary: HexValue
  color_background: HexValue
  images: {
    logo: string
    favicon: string
  }
  urls: {
    tenantUrl: string
  }
  menu_links?: MenuLink[]
  url_facebook?: string
  url_linkedin?: string
  url_x_twitter?: string
}

export const fetchCustomization = async (tenantToken: string): Promise<Customization> => {
  const fetchErrorMessage = 'Tenant customization configuration not found'
  try {
    return await fetch(`${basePlatformApiURL()}/v2/tenants/${tenantToken}/customization`).then(
      async (response) => {
        if (!response.ok) {
          throw new Error(fetchErrorMessage)
        }

        const json = (await response.json()) as CustomizationResponse
        const tenantCustomization: Customization = {
          colors: {
            primary: json?.color_primary ?? DEFAULT_COLORS.TEAL,
            secondary: json?.color_secondary ?? DEFAULT_COLORS.BLUE,
            background: json?.color_background ?? DEFAULT_COLORS.WHITE
          },
          other: {
            logo: json?.images?.logo ?? DEFAULT_LOGO_PATH,
            favicon: json?.images?.favicon,
            tenantUrl: json?.urls?.tenantUrl ?? DEFAULT_TENANT_URL,
            menuLinks: json?.menu_links ?? [],
            urlFacebook: json?.url_facebook ?? '',
            urlLinkedin: json?.url_linkedin ?? '',
            urlXTwitter: json?.url_x_twitter ?? ''
          }
        }
        return tenantCustomization
      }
    )
  } catch (_error) {
    throw new Error(fetchErrorMessage)
  }
}

export const isCustomization = (data: unknown): data is Customization => {
  return typeof data === 'object' && data !== null && 'colors' in data
}
