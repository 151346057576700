import { useEffect, useState } from 'react'

import { useTenantContext, useUserContext } from 'providers'
import { addTenantToMonitoringContext, addUserToMonitoringContext } from 'utils'
import { AppLoader } from 'components/app-loader'

export const MonitoringContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const user = useUserContext()
  const tenant = useTenantContext()

  const [isTenantMonitored, setIsTenantMonitored] = useState(false)
  const [isUserMonitored, setIsUserMonitored] = useState(false)
  const isMonitoringContextReady = isTenantMonitored && isUserMonitored

  useEffect(() => {
    addUserToMonitoringContext(user)
    setIsUserMonitored(true)
  }, [user.id])

  useEffect(() => {
    addTenantToMonitoringContext(tenant)
    setIsTenantMonitored(true)
  }, [tenant.token])

  if (!isMonitoringContextReady) return <AppLoader />

  return <>{children}</>
}
