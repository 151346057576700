import camelcaseKeys from 'camelcase-keys'
import { type Tenant } from 'types'
import { basePlatformApiURL } from 'utils/UrlHelper'

export const getTenant = async (tenantToken: string): Promise<Tenant> => {
  const fetchErrorMessage = 'Error fetching tenant'
  try {
    const apiUrl = `${basePlatformApiURL()}/tenants/${tenantToken}`
    return await fetch(apiUrl).then(async (response) => {
      if (response.ok) {
        const data = (await response.json()) as unknown
        if (isTenantData(data)) {
          const transformedData: Tenant = transformData(data)

          return transformedData
        }
      }

      throw Error(fetchErrorMessage)
    })
  } catch (_error) {
    throw Error(fetchErrorMessage)
  }
}

export interface TenantResponse {
  address_city: string | null
  address_country: string | null
  address_state: string | null
  address_street: string | null
  address_unit: string | null
  address_zip_code: string | null
  aging_type: string | null // union?
  alias_of_id: number | null
  app_endpoint: string | null
  attachments_email_address: string | string[]
  bcc_user_email: boolean | null
  contact_email_address: string | null
  contact_first_name: string | null
  contact_last_name: string | null
  contact_phone: string | null
  contract_length_months: string | null
  contract_renew_date: string | null
  contract_sign_date: string | null
  created_at: string
  disabled: boolean | null
  from_email_address: string | null
  funding_request_notification_email: string | null
  hellosign_enabled: boolean | null
  hide_email_logo: boolean | null
  id: number
  new_leads_email_address: string | string[]
  notifications_email_address: string | string[]
  num_licenses: string | null
  operate_enabled: boolean | null
  operations_attachments_email_address: string | string[]
  operations_notifications_email_address: string | string[]
  organization_name: string
  organization_type: string // union?
  organization_website: string | null
  origination_type: string // union?
  preheader_email_color: string | null
  qb_oauth_version: number
  reference_num: string | null
  return_user_attachments_email_address: string | string[]
  return_user_notifications_email_address: string | string[]
  schedule_batch_start: string
  show_copyright: boolean | null
  show_tracking_data: boolean | null
  tenant_timezone: string
  tenant_type: 'tenant' | 'alias'
  token: string
  updated_at: string
}

export const isTenantData = (data: unknown): data is TenantResponse => {
  return typeof data === 'object' && data !== null && 'address_city' in data
}

export const isTenant = (data: unknown): data is Tenant => {
  return typeof data === 'object' && data !== null && 'addressCity' in data
}

const transformData = (data: TenantResponse): Tenant => {
  const camlelizedData = camelcaseKeys(
    data as unknown as Record<string, unknown>
  ) as unknown as Tenant
  const formattedData = {
    ...camlelizedData,
    bccUserEmail: Boolean(camlelizedData.bccUserEmail),
    contractRenewDate:
      camlelizedData.contractRenewDate !== null ? new Date(camlelizedData.contractRenewDate) : null,
    contractSignDate:
      camlelizedData.contractSignDate !== null ? new Date(camlelizedData.contractSignDate) : null,
    createdAt: new Date(camlelizedData.createdAt),
    disabled: Boolean(camlelizedData.disabled),
    hellosignEnabled: Boolean(camlelizedData.hellosignEnabled),
    hideEmailLogo: Boolean(camlelizedData.hideEmailLogo),
    operateEnabled: Boolean(camlelizedData.operateEnabled),
    showCopyright: Boolean(camlelizedData.showCopyright),
    showTrackingData: Boolean(camlelizedData.showTrackingData),
    referenceNum: Number(camlelizedData.referenceNum),
    scheduleBatchStart: Number(camlelizedData.scheduleBatchStart),
    updatedAt: new Date(camlelizedData.updatedAt)
  }
  return formattedData
}
