import {
  Tooltip,
  ActionIcon,
  Menu,
  Group,
  Anchor,
  type ActionIconProps,
  useMantineTheme
} from '@mantine/core'
import { useState } from 'react'
import { SignOutButton } from 'components/Header/SignOutButton/SignOutButton'
import { IoEllipsisHorizontalSharp } from 'react-icons/io5'
import { FaPhone, FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import { FaSquareXTwitter } from 'react-icons/fa6'
import styled from '@emotion/styled'
import { useChangePasswordModal } from 'hooks/useChangePasswordModal'
import { useTenantContext } from 'providers'

interface ActionIconClickableProps extends ActionIconProps {
  onClick: () => void
}

const ESignIcon = styled(ActionIcon)<ActionIconClickableProps>`
  color: inherit;
`

const EllipsisMenu = (): JSX.Element => {
  const {
    colors: { primary },
    other: { menuLinks, urlFacebook, urlLinkedin, urlXTwitter }
  } = useMantineTheme()
  const { contactPhone } = useTenantContext()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { ChangePasswordModal, open } = useChangePasswordModal()
  const iconColor = primary[6]

  return (
    <>
      <Menu
        shadow="md"
        transitionProps={{ transition: 'rotate-left', duration: 150 }}
        opened={isMenuOpen}
        onChange={setIsMenuOpen}
        width="15.5em"
      >
        <Menu.Target>
          <Tooltip label="Expanded Menu" events={{ hover: true, focus: true, touch: false }}>
            <ESignIcon
              aria-label="Header Expanded Menu"
              variant="transparent"
              onClick={() => {
                setIsMenuOpen(true)
              }}
            >
              <IoEllipsisHorizontalSharp size="2em" />
            </ESignIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          {menuLinks?.map((menuLink) => (
            <Menu.Item
              component="a"
              href={menuLink.url}
              sx={{ color: iconColor }}
              key={`menuItem-${menuLink.label}`}
            >
              <Group spacing="md" position="center">
                {menuLink.label}
              </Group>
            </Menu.Item>
          ))}
          <Menu.Item
            onClick={() => {
              open()
            }}
            sx={{ color: iconColor }}
          >
            <Group spacing="md" position="center">
              Change Password
            </Group>
          </Menu.Item>
          <Menu.Item component="span">
            <SignOutButton />
          </Menu.Item>
          {contactPhone != null && contactPhone !== '' && (
            <Menu.Item component="a" href={`tel:${contactPhone}`} sx={{ color: iconColor }}>
              <Group spacing="md" position="center">
                <FaPhone />
                {contactPhone}
              </Group>
            </Menu.Item>
          )}
          <Group spacing="md" position="center">
            {urlFacebook != null && urlFacebook.length > 0 && (
              <Anchor href={urlFacebook} target="_blank" data-testid="FacebookIcon">
                <FaFacebookSquare size="2em" style={{ color: iconColor }} title="Facebook" />
              </Anchor>
            )}
            {urlLinkedin != null && urlLinkedin.length > 0 && (
              <Anchor href={urlLinkedin} target="_blank" data-testid="LinkedInIcon">
                <FaLinkedin size="2em" style={{ color: iconColor }} title="LinkedIn" />
              </Anchor>
            )}
            {urlXTwitter != null && urlXTwitter.length > 0 && (
              <Anchor href={urlXTwitter} target="_blank" data-testid="XTwitterIcon">
                <FaSquareXTwitter size="2em" style={{ color: iconColor }} title="XTwitter" />
              </Anchor>
            )}
          </Group>
        </Menu.Dropdown>
      </Menu>
      <ChangePasswordModal />
    </>
  )
}

export default EllipsisMenu
