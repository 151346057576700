import { Space, Stack, Title } from '@mantine/core'
import { Disclaimer } from './Disclaimer'
import { Step } from './Step'
import React, { useEffect, useState } from 'react'

export const Section = ({ section, sectionName, setStep }) => {
  return (
    <Stack spacing="md">
      <Space h="md" />
      {Object.keys(section).map((key, index) => {
        return (
          <Step step={section[key]} stepName={key} key={key} stepNumber={index} setStep={setStep} />
        )
      })}
    </Stack>
  )
}

export const Sections = ({ buildReviewSection, setStep }) => {
  const [sections, setSections] = useState([])

  useEffect(() => {
    buildReviewSection().then((sections) => {
      setSections(sections)
    })
  }, [])

  return (
    <Stack spacing="md">
      {Object.keys(sections).map((key) => (
        <Section section={sections[key]} sectionName={key} key={key} setStep={setStep} />
      ))}
      <Disclaimer />
    </Stack>
  )
}
