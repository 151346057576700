import { Skeleton } from '@mantine/core'

import { SkeletonForm } from 'Form/SkeletonForm'
import { AppContainer } from 'components/app-container'

export const AppLoader: React.FC = () => {
  return (
    <>
      <Skeleton height={62} width="100%" />
      <AppContainer>
        <SkeletonForm />
      </AppContainer>
    </>
  )
}
