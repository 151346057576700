import { Badge, Tooltip, ActionIcon, type ActionIconProps } from '@mantine/core'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { faFileContract } from '@fortawesome/free-solid-svg-icons'
import { useSignatureRequests } from 'hooks/useSignatureRequests'
import { storage } from 'utils/storage'
import useSignerModalState from 'hooks/useSignerModalState'
import SignatureRequestModal from 'components/SignatureRequests/SignatureRequestModal'
import styled from '@emotion/styled'

interface ActionIconClickableProps extends ActionIconProps {
  onClick: () => void
}

interface StyledIconProps {
  iconcolor?: string
  icon: IconProp
}

const ESignIcon = styled(ActionIcon)<ActionIconClickableProps>`
  position: relative;
  color: inherit;
`
const PillContainer = styled.div`
  position: absolute;
  top: -0.75em;
  right: -0.75em;
`

const DocumentIcon = styled(FontAwesomeIcon)<StyledIconProps>`
  color: ${(props) => props.iconcolor};
`

const SignatureRequests = (): JSX.Element => {
  const { state, setSignerModalState } = useSignerModalState()

  const {
    data: signatureRequests = [],
    error: signatureRequestError,
    isError: isSignatureRequestError
  } = useSignatureRequests()

  const docCount = signatureRequests.length

  useEffect(() => {
    const isSignatureDismissed = storage.get('signatureRequestAlertDismissed') === 'true'

    if (!isSignatureDismissed && docCount > 0) {
      setSignerModalState('alert')
    }
  }, [docCount])

  if (isSignatureRequestError) {
    console.error(signatureRequestError)
  }

  return (
    <>
      <Tooltip label="eSign Documents" events={{ hover: true, focus: true, touch: false }}>
        <ESignIcon
          aria-label="eSign Documents Button"
          variant="transparent"
          onClick={() => {
            setSignerModalState('list')
          }}
        >
          {
            // TODO - FP-6235 - Fix color to use new config theme
          }
          <DocumentIcon icon={faFileContract} size="xl" />
          {docCount > 0 && (
            <PillContainer>
              <Badge size="xs" sx={{ color: 'black' }}>
                {docCount}
              </Badge>
            </PillContainer>
          )}
        </ESignIcon>
      </Tooltip>
      <SignatureRequestModal state={state} setSignerModalState={setSignerModalState} />
    </>
  )
}

export default SignatureRequests
