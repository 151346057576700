import { useContext, useEffect, useState } from 'react'
import SectionContext from '../Context/Section.context'
import { SectionNav } from './SectionNav/SectionNav'
import { Document } from 'Form/Document/Document'
import { SectionWrapper } from './Section.styles'
import type { Section as ISection, ReviewForm, Document as DocumentType } from 'types'
import type { Deal } from 'services/deals'

interface FormInstance {
  deal: Deal[]
}

interface SectionProps {
  section: ISection
  formInstance: FormInstance
  buildReviewSection: (section: ISection) => ReviewForm
}

export const Section = ({
  section,
  formInstance,
  buildReviewSection
}: SectionProps): JSX.Element | null => {
  const { setInitialValues, currentStep } = useContext(SectionContext)
  const [isLoading, setIsLoading] = useState(true)
  const contactEmail = formInstance.deal[0].business_contacts[0].email

  useEffect(() => {
    setInitialValues({
      currentStep,
      dealId: `${formInstance.deal[0].id}`
    })

    setIsLoading(false)
  }, [formInstance])

  const sectionToRender = (section: ISection): JSX.Element | undefined => {
    if (section.steps.length > 0) {
      return (
        <>
          <SectionNav
            key={`${section.name}-nav`}
            steps={section.steps}
            stepSize={section?.steps.length}
            buildReviewSection={buildReviewSection}
          />
        </>
      )
    }
  }

  const documentToRender = (section: ISection): JSX.Element | undefined => {
    if (section.documents.length > 0) {
      return (
        <>
          {section.documents.map((doc: DocumentType) => {
            return <Document document={doc} key={doc.name} />
          })}
        </>
      )
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <SectionWrapper>
      <div>
        <h1 className="section-title">{section?.name}</h1>
        <p className="contact-email">{contactEmail}</p>
      </div>
      <div className="section-content">{sectionToRender(section)}</div>
      {documentToRender(section)}
    </SectionWrapper>
  )
}
