import styled from 'styled-components'

export const StepGroupWrapper = styled.div`
  .field_container {
    margin-top: 20px;
  }

  .label {
    color: #58595b;
    font-weight: 500;
    font-size: 16px;
  }

  .append_button {
    margin-top: 20px;
    letter-spacing: 0.3px;
    min-width: 150px;
    padding: 9px 19px;
    font-size: 16px;
    border-radius: 3px;
  }
`
