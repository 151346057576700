type StorageKey = 'identifier' | 'currentStep' | 'signatureRequestAlertDismissed'

export const storage = {
  clear: (): void => {
    localStorage.clear()
  },
  get: (key: StorageKey): string | null => {
    return localStorage.getItem(key)
  },
  remove: (key: StorageKey): void => {
    localStorage.removeItem(key)
  },
  set: (key: StorageKey, value: string): void => {
    localStorage.setItem(key, value)
  }
}
