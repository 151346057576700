import { ErrorBoundary } from 'react-error-boundary'

import { AppError } from 'components/app-error'

const Fallback = ({ error }: { error: Error }): JSX.Element => {
  return <AppError message={`App could not be loaded - ${error.message}`} />
}

export const AppBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <ErrorBoundary fallbackRender={Fallback}>{children}</ErrorBoundary>
}
