import React, { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import { Button, Group, Title } from '@mantine/core'
import {
  Input,
  Select,
  RadioUpload,
  Radio,
  FollowingQuestionRadio,
  CheckBox,
  TextareaInput
} from '@baseComponents'
import { StepGroupWrapper } from './StepGroup.styles'
import { Tooltip } from '../../components/Tooltip/Tooltip'
import pluralize from 'pluralize'

export const StepGroup = ({
  group,
  fields: templateFields,
  model,
  control,
  register,
  subtitle,
  setValue,
  getValues,
  errors,
  clearErrors,
  trigger
}) => {
  const fieldArrayName = model === 'deal' ? `${model}` : `deal[0].${pluralize.plural(model)}`

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArrayName
  })

  const copyContactDetails = () => {
    const fields = ['email', 'last_name', 'name', 'phone_number', 'title']
    fields.forEach((field) => {
      const officerField = `deal[0].business_officers[0].${field}`
      const contactField = `deal[0].business_contacts[0].${field}`
      setValue(officerField, getValues(contactField))
      clearErrors(officerField)
    })
  }

  const getSelectedOption = (field, index) => {
    const fieldName = `${fieldArrayName}[${index}].${field.field_name}`
    const currentValue = getValues(fieldName)
    const selectedOption = field.options.find((option) => option.value === currentValue)
    return selectedOption
  }

  const shouldRenderFollowUpFields = (field, model, index) => {
    if (field.options.length > 0) {
      const selectedOption = getSelectedOption(field, index)
      return (
        selectedOption !== null &&
        selectedOption !== undefined &&
        selectedOption.is_follow_up_option
      )
    }
    return false
  }

  const cleanUnselectedFollowUpFields = (field, index) => {
    const fieldName = `${fieldArrayName}[${index}].${field.field_name}`
    const currentValue = getValues(fieldName)
    const followUpOptions = field.options.filter((option) => option.is_follow_up_option)
    if (
      followUpOptions.length > 0 &&
      !followUpOptions.some((option) => option.value === currentValue)
    ) {
      followUpOptions.forEach((followUpOption) => {
        followUpOption.follow_up_fields.forEach((followUpField) => {
          setValue(`${fieldArrayName}[${index}].${followUpField.field_name}`, '')
        })
      })
    }
  }

  useEffect(() => {
    if (templateFields?.length === 0 || fields?.length === 0) return

    fields.map((_field, index) => {
      templateFields.map((field) => {
        cleanUnselectedFollowUpFields(field, index)
      })
    })
  }, [fields, templateFields])

  const renderFields = (templateFields, index) => {
    const fieldsToRender = templateFields.map((field) => {
      if (field.is_following_question) return null
      if (!field.show_borrower_side) return null
      return (
        <div key={`key-${model}-${field.field_label}-${index}`}>
          <div
            key={`main-field-${model}-${field.field_label}-${index}`}
            className="field_container"
          >
            {inputToRender({
              field,
              index,
              register,
              setValue,
              errors,
              clearErrors,
              getValues,
              fieldArrayName,
              control,
              trigger
            })}
          </div>
          {shouldRenderFollowUpFields(field, model, index) && (
            <div
              key={`follow-up-field-${model}-${field.field_label}-${index}`}
              className="field_container"
            >
              {getSelectedOption(field, index).follow_up_fields.map((field, index) => {
                return (
                  <div key={`idx-${index}`}>
                    {inputToRender({
                      field,
                      index,
                      register,
                      setValue,
                      errors,
                      clearErrors,
                      getValues,
                      fieldArrayName,
                      control,
                      trigger
                    })}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )
    })

    return showCopyContactDetailsButton(model, index)
      ? [copyContactDetailsButton(index), ...fieldsToRender]
      : fieldsToRender
  }

  const showCopyContactDetailsButton = (model, index) => {
    if (model === 'business_officer' && index === 0) {
      return true
    }

    return false
  }

  const copyContactDetailsButton = (index) => {
    return (
      <div key={index} style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}>
        <Button onClick={copyContactDetails}>Copy Contact Details</Button>
      </div>
    )
  }

  const renderSubtitle = (index) => {
    if (group.repeatable && group.show_repeatable_counter) {
      return (
        <Title key={`subtitle_${index}`} color="#393F44" order={4} span>{`${subtitle} ${
          index + 1
        }`}</Title>
      )
    } else if (group.show_group_name) {
      return <Title key={`subtitle_${index}`} color="#393F44" order={4} span>{`${subtitle}`}</Title>
    } else {
      return null
    }
  }

  const showAppendButton = () => {
    if (group.repeatable) {
      const currentGroups = getValues(fieldArrayName).length
      if (currentGroups >= group.repeatable_min && currentGroups < group.repeatable_max) {
        return true
      }
      return false
    }
    return false
  }

  const canRemoveAll = group.repeatable && group.repeatable_min === 0
  return (
    <StepGroupWrapper>
      {fields.map((_field, index) => {
        return (
          <div key={index}>
            <Group position="left" grow>
              {renderSubtitle(index)}

              {(canRemoveAll || index > 0) && (
                <Group my="10px" position="right">
                  <Button onClick={() => remove(index)} color="red">
                    Remove
                  </Button>
                </Group>
              )}
            </Group>

            {renderFields(templateFields, index)}
          </div>
        )
      })}
      {showAppendButton() && (
        <Group my="10px">
          <Button className="append_button" onClick={() => append({})}>
            {group.repeatable_button_label ? group.repeatable_button_label : 'Append'}
          </Button>
        </Group>
      )}
    </StepGroupWrapper>
  )
}

export const inputToRender = ({
  field,
  index,
  register,
  setValue,
  errors,
  clearErrors,
  getValues,
  fieldArrayName,
  control,
  trigger
}) => {
  const fieldName = `${fieldArrayName}[${index}].${field.field_name}`

  if (field.field_type === 'checkbox') {
    return (
      <CheckBox field={field} fieldName={fieldName} control={control} renderLabel={renderLabel} />
    )
  } else if (field.field_type === 'radio') {
    return (
      <Radio
        field={field}
        fieldName={fieldName}
        register={register}
        key={fieldName}
        setValue={setValue}
        errors={errors}
        clearErrors={clearErrors}
        getValues={getValues}
        renderLabel={renderLabel}
      />
    )
  } else if (field.field_type === 'dropdown') {
    return (
      <Select
        field={field}
        fieldName={fieldName}
        control={control}
        key={fieldName}
        renderLabel={renderLabel}
        placeholder={field.placeholder}
      />
    )
  } else if (field.field_type === 'radio_upload') {
    return (
      <RadioUpload
        field={field}
        fieldName={fieldName}
        register={register}
        key={fieldName}
        setValue={setValue}
        getValues={getValues}
        errors={errors}
        renderLabel={renderLabel}
        clearErrors={clearErrors}
      />
    )
  } else if (field.field_type === 'radio_following_question') {
    return (
      <FollowingQuestionRadio
        field={field}
        fieldName={fieldName}
        register={register}
        key={fieldName}
        setValue={setValue}
        errors={errors}
        clearErrors={clearErrors}
        getValues={getValues}
        control={control}
      />
    )
  } else if (field.field_type === 'textarea') {
    return (
      <TextareaInput
        field={field}
        fieldName={fieldName}
        control={control}
        key={`${fieldName}${index}`}
        setValue={setValue}
        clearErrors={clearErrors}
        renderLabel={renderLabel}
        trigger={trigger}
      />
    )
  } else {
    return (
      <Input
        field={field}
        fieldName={fieldName}
        control={control}
        key={`${fieldName}${index}`}
        setValue={setValue}
        clearErrors={clearErrors}
        renderLabel={renderLabel}
        trigger={trigger}
      />
    )
  }
}
const TooltipedLabel = ({ field }) => {
  return (
    <span>
      <span className="label">{field.field_label}</span>
      <Tooltip tip={field.tooltip} />
    </span>
  )
}

const renderLabel = (field) => {
  return field.tooltip ? (
    <TooltipedLabel field={field} />
  ) : (
    <label className="label">{field.field_label}</label>
  )
}
