import styled from 'styled-components'

export const StepFormWrapper = styled.div`
  .navigation_button {
    padding: 9px 19px;
    letter-spacing: 0.3px;
    box-sizing: border-box;
    min-width: 150px;
    border-radius: 3px;
    font-size: 16px;
  }
`
