import { Suspense, useState } from 'react'
import {
  Modal,
  Table,
  Button,
  Badge,
  Group,
  ActionIcon,
  type ActionIconProps,
  useMantineTheme
} from '@mantine/core'
import { useSignatureRequests } from 'hooks/useSignatureRequests'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { BsExclamationCircle } from 'react-icons/bs'
import { storage } from 'utils/storage'
import { type SignatureRequest } from 'types'
import { type SignerModalState } from 'hooks/useSignerModalState'
import HelloSignContainer from 'components/SignatureRequests/HelloSignContainer'
import styled from 'styled-components'
import { useUserContext } from 'providers'

interface SignatureRequestModalProps {
  setSignerModalState: (state: SignerModalState) => void
  state: string
}

interface ActionIconClickableProps extends ActionIconProps {
  onClick: () => void
}

interface BackButtonProps {
  icon: IconProp
  iconcolor: string
}

const BackButton = styled(ActionIcon)<ActionIconClickableProps>`
  position: absolute;
  top: 15px;
  z-index: 1000;
  color: #868e96;
`

const SignDocumentH1 = styled.h1`
  margin-top: 0;
  margin-bottom: 1rem;
`

const BackButtonIcon = styled(FontAwesomeIcon)<BackButtonProps>`
  color: ${(props) => props.iconcolor};
`

const SignatureRequestModal = ({
  setSignerModalState,
  state
}: SignatureRequestModalProps): JSX.Element => {
  const {
    colors: { primary }
  } = useMantineTheme()
  const [selectedSGid, setSelectedSGid] = useState<string>('')
  const {
    data: signatureRequests,
    error: signatureRequestError,
    isError: isSignatureRequestError
  } = useSignatureRequests()
  const user = useUserContext()

  const mantineTheme = useMantineTheme()

  if (isSignatureRequestError) {
    console.error(signatureRequestError)
  }

  const renderModalContent = (): JSX.Element => {
    switch (state) {
      case 'alert':
        return (
          <Modal
            opened
            onClose={() => {
              storage.set('signatureRequestAlertDismissed', 'true')
              setSignerModalState('closed')
            }}
            sx={{ textAlign: 'center' }}
          >
            <BsExclamationCircle size="4rem" color={primary[6]} />
            <h2>
              You have {signatureRequests?.length} Document
              {(signatureRequests?.length !== undefined && signatureRequests?.length !== 1) ??
                's'}{' '}
              to sign
            </h2>
            <Group position="center">
              <Button
                sx={{ width: '11rem' }}
                onClick={() => {
                  setSignerModalState('list')
                }}
              >
                Sign Document
                {(signatureRequests?.length !== undefined && signatureRequests?.length !== 1) ??
                  's'}{' '}
                Now
              </Button>
              <Button
                sx={{ width: '11rem' }}
                onClick={() => {
                  storage.set('signatureRequestAlertDismissed', 'true')
                  setSignerModalState('closed')
                }}
              >
                Later
              </Button>
            </Group>
          </Modal>
        )
      case 'document':
        return (
          <Modal
            opened
            onClose={() => {
              setSignerModalState('closed')
            }}
            size="100%"
          >
            <BackButton
              aria-label="Back to all documents"
              variant="transparent"
              onClick={() => {
                setSignerModalState('list')
              }}
            >
              <BackButtonIcon
                icon={faArrowLeft}
                size="lg"
                iconcolor={mantineTheme.colors.gray[6]}
              />
            </BackButton>
            <Suspense fallback={<div>Loading...</div>}>
              <HelloSignContainer sGid={selectedSGid} />
            </Suspense>
          </Modal>
        )
      default:
        return (
          <Modal
            opened={state !== 'closed'}
            onClose={() => {
              setSignerModalState('closed')
            }}
          >
            <SignDocumentH1>Sign Documents</SignDocumentH1>
            <h3>
              You have {signatureRequests?.length} Document
              {(signatureRequests?.length !== undefined && signatureRequests?.length !== 1) ??
                's'}{' '}
              to sign
            </h3>
            <div style={{ position: 'relative' }}>
              <h2 style={{ display: 'inline-block', paddingRight: '1rem' }}>All Documents </h2>
              <Badge
                size="lg"
                sx={{
                  color: 'white',
                  position: 'absolute',
                  top: `1.7rem`,
                  textTransform: 'none'
                }}
              >
                {signatureRequests?.length} result{(signatureRequests?.length ?? 0) !== 1 && 's'}
              </Badge>
            </div>
            <Table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {signatureRequests?.map((signatureRequest: SignatureRequest): JSX.Element => {
                  return (
                    <tr key={signatureRequest.document?.id}>
                      <td>{signatureRequest.template}</td>
                      <td>{new Date(signatureRequest.createdAt).toDateString()}</td>
                      <td>
                        <Button
                          aria-label={`View ${signatureRequest.document.template.label}`}
                          onClick={() => {
                            setSelectedSGid(
                              signatureRequest.signers.find((signer) => {
                                return signer.email === user?.emailAddress
                              })?.sGid ?? ''
                            )
                            setSignerModalState('document')
                          }}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Modal>
        )
    }
  }

  return renderModalContent()
}

export default SignatureRequestModal
