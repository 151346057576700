import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useDealIdentifier } from './useDealIdentifier'
import { getSignatureRequestURL } from 'services/signatureRequest'
import { type SignatureRequestURL } from 'types'

export const useSignatureRequestURL = (sGid: string): UseQueryResult<SignatureRequestURL> => {
  const identifierToken = useDealIdentifier() ?? ''

  return useQuery<SignatureRequestURL, unknown>({
    queryKey: makeURLQueryKey(sGid),
    queryFn: async () => await getSignatureRequestURL(sGid, identifierToken),
    enabled: sGid !== null && sGid !== undefined && sGid !== ''
  })
}

export const makeURLQueryKey = (sGid?: string): Array<string | undefined> => ['sign-url', sGid]
