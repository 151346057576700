import { createContext, useContext } from 'react'
import type { Customization } from 'types'

interface Provider {
  children: React.ReactNode
  customization: Customization
}

const CustomizationContext = createContext<Customization | null>(null)

export const CustomizationProvider = ({
  children,
  customization
}: Provider): React.ReactElement => {
  return (
    <CustomizationContext.Provider value={customization}>{children}</CustomizationContext.Provider>
  )
}

export const useCustomizationContext = (): Customization => {
  const context = useContext(CustomizationContext)

  if (context === null) {
    throw new Error('useCustomizationContext must be used within a CustomizationProvider')
  }

  return context
}
