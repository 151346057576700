import { useState } from 'react'

import { type AppAccess, type BankConnectionError } from 'types'
import { createBankConnection } from 'services/bank'

export interface BankConnectionCreation {
  createConnection: (publicToken: string) => Promise<void>
  error: null | BankConnectionError
  loading: boolean
  result: null | AppAccess
}

/**
 * This hook creates a new bank connection object for a given deal entity
 *
 * @param {string} identifier        - The deal entity identifier.
 * @param {number} dealId            - The deal id.
 *
 * @returns {BankConnectionCreation} - The bank connection creation result,
 *                                     trigger, error, and loading status
 */
export const useBankConnectionCreate = (
  identifier: string,
  dealId: number
): BankConnectionCreation => {
  const [result, setResult] = useState<null | AppAccess>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<null | BankConnectionError>(null)

  /**
   * Create the bank connection.
   *
   * @param {string} publicToken - The public token generated by completing
   *                               the Plaid Link flow
   */
  const createConnection = async (publicToken: string): Promise<void> => {
    setLoading(true)

    if (identifier === '') {
      setLoading(false)
      setError({ message: 'Identifier is empty' })
      return
    }

    await createBankConnection(identifier, dealId, publicToken)
      .then((result) => {
        const appAccess = result
        const entityId = String(dealId)

        if (appAccess.id === null || appAccess.entity_id !== entityId) {
          setLoading(false)
          setError({ message: 'Invalid access response' })
          setLoading(false)
          return
        }

        setResult(result)
        setLoading(false)
      })
      .catch((error: Error) => {
        console.log(error.message)
        setError({ message: error.message })
        setLoading(false)
      })
  }

  return { createConnection, error, loading, result }
}
