import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { RouterError } from 'components/router-error'
import { SubmittedDeal } from 'components/SubmittedDeal/SubmittedDeal'
import { UnderwriteForm } from 'Form/UnderwriteForm'
import { BankConnect } from 'views/prospect/Bank/BankConnect'
import { NotFoundPage } from 'components/not-found-page'
import { Root } from 'components/root'
import { Suspense } from 'react'
import { AppLoader } from 'components/app-loader'
import { AppContainer } from 'components/app-container'

export const routes = [
  {
    path: '/',
    element: <Root />,
    errorElement: (
      <AppContainer>
        <RouterError message="App could not be loaded" />
      </AppContainer>
    ),
    children: [
      {
        index: true,
        element: <UnderwriteForm />,
        errorElement: <RouterError message="Form could not be loaded" />
      },
      {
        path: 'bank-connect',
        element: <BankConnect />,
        errorElement: <RouterError message="Bank connection form could not be loaded" />
      },
      {
        path: 'submitted',
        element: <SubmittedDeal />,
        errorElement: <RouterError message="Form success page could not be loaded" />
      },
      {
        path: 'error',
        element: <RouterError message="Form submission error" />,
        errorElement: <RouterError message="Form error page could not be loaded" />
      },
      {
        path: '*',
        element: <NotFoundPage />,
        errorElement: <RouterError message="404 page could not be loaded" />
      }
    ]
  }
]

const router = createBrowserRouter(routes)

export const Router: React.FC = () => {
  return (
    <Suspense fallback={<AppLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  )
}
