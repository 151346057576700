import type { Features } from 'types'

import { createContext, Suspense, useContext } from 'react'

import { AppLoader } from 'components/app-loader'
import { useGetFlipperFeatures } from 'hooks/useGetFlipperFeatures'
import { defaultFlipperFeatures } from 'services/flipper'

interface Props {
  children: React.ReactNode
}

export const FeaturesContext = createContext<Features>(defaultFlipperFeatures)

export const FeaturesProvider = ({ children }: Props): React.ReactElement => {
  const { data: features } = useGetFlipperFeatures()

  return (
    <Suspense fallback={<AppLoader />}>
      <FeaturesContext.Provider value={features ?? defaultFlipperFeatures}>
        {children}
      </FeaturesContext.Provider>
    </Suspense>
  )
}

export const useFeaturesContext = (): Features => {
  const context = useContext(FeaturesContext)

  return context
}
