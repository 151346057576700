import { Anchor, Group, Modal, Stack } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useForm } from 'react-hook-form'

import { Radio } from '../../../BaseComponents/Radio/Radio'
import { type Field } from 'types'
import { useDisclaimerContext } from 'Form/Context/Disclaimer.context'

export const Disclaimer = (): JSX.Element => {
  const [opened, { open, close }] = useDisclosure(false)
  const { disclaimer, setagreedToDisclaimerTerms } = useDisclaimerContext()

  const {
    agree_required: agreeRequired,
    body_html: disclaimerHtml,
    details: {
      body_html: detailsHtml,
      title_text: detailsTitleText,
      trigger_text: detailsTriggerText
    }
  } = disclaimer

  const fieldName = 'agree_to_terms'
  const field: Field = {
    field_label: 'Agree to Terms',
    field_name: fieldName,
    default_value: 'no',
    dynamic: false,
    model: 'deal',
    required: false,
    field_type: 'radio',
    id: 123,
    repeatable_index: 0,
    show_lender_side: true,
    tooltip: null,
    options: [
      { label: 'I Agree', value: 'yes', is_file: false },
      { label: 'I Disagree', value: 'no', is_file: false }
    ]
  }

  interface FormValues {
    [fieldName]: string
  }
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm<FormValues>()

  return (
    <Stack spacing="md">
      {disclaimerHtml !== null && (
        <div data-testid="disclaimer-html" dangerouslySetInnerHTML={{ __html: disclaimerHtml }} />
      )}
      {detailsTriggerText !== null && detailsHtml !== null && (
        <Group position="center" data-testid="details">
          <Anchor
            data-testid="details-trigger-text"
            component="button"
            onClick={open}
            type="button"
            sx={{ textDecoration: 'underline' }}
          >
            {detailsTriggerText}
          </Anchor>
          <Modal
            data-testid="details-modal"
            opened={opened}
            onClose={close}
            title={detailsTitleText ?? 'Disclaimer'}
            centered
            size="xl"
            closeButtonProps={{ 'aria-label': 'Close modal' }}
          >
            <div data-testid="details-html" dangerouslySetInnerHTML={{ __html: detailsHtml }} />
          </Modal>
        </Group>
      )}
      {agreeRequired && (
        <Radio
          field={field}
          fieldName={fieldName}
          register={register}
          key={fieldName}
          setValue={(name: typeof fieldName, value: string) => {
            setValue(name, value)
            setagreedToDisclaimerTerms(value === 'yes')
          }}
          errors={errors}
          clearErrors={clearErrors}
          getValues={getValues}
          renderLabel={() => {
            // Don't render a label. Ideally we'd use a visually-hidden label,
            // but the <Radio> component doesn't support that yet.
          }}
        />
      )}
    </Stack>
  )
}
