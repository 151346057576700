import { Button } from '@mantine/core'
import { appendPathToURL, reactPlatformURL } from 'utils'
import { useStyles } from './SignOutButton.styles'
import { invalidateUserSession } from 'services/users'
import { useDealIdentifier } from 'hooks/useDealIdentifier'
import { showError } from 'utils/notifications'
import { storage } from 'utils/storage'

export const SignOutButton = (): JSX.Element => {
  const { classes } = useStyles()
  const identifier: string | null = useDealIdentifier()

  const handleClick = (): void => {
    void handleSignOut()
  }

  const handleSignOut = async (): Promise<void> => {
    if (identifier === null) return

    await invalidateUserSession(identifier).then((success) => {
      if (success) {
        storage.remove('currentStep')
        window.location.assign(appendPathToURL(reactPlatformURL(), '/signin'))
      } else {
        showError('Error signing out')
        console.error('Error signing out')
      }
    })
  }

  return (
    <Button className={classes.signOutButton} onClick={handleClick}>
      Sign Out
    </Button>
  )
}
