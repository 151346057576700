import styled from 'styled-components'

export const UploadButtonWrapper = styled.button`
  width: 100%;
  height: 42px;
  border: 1px solid #cfd1d2;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  transition: border 0.2s ease-in-out;
  white-space: nowrap;
  background-color: #fff;

  &:hover {
    border-color: #393f44;
  }
`
