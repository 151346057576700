import type { Field } from '../types'
import Inputmask from 'inputmask'

const fieldFormatter = (field: Field): ((value: string) => string) | undefined => {
  switch (field.field_type) {
    case 'email':
    case 'percentage':
    case 'currency':
    case 'tel':
    case 'number':
    case 'ein':
    case 'ssn':
      return (value) => maskFormatter(field, value)
    case 'date':
      return (value) =>
        new Date(`${value}T00:00:00`).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        })
    case 'radio':
      return (value) => field.options.find((option) => option.value === value)?.label ?? value
    case 'radio_upload':
      return (value) => formatRadioUpload(value)
    default:
      return undefined
  }
}

const formatRadioUpload = (value: string): string => {
  if (value === '' || value === null) {
    return 'Not Uploaded'
  } else if (value === 'later') {
    return 'Upload Later'
  }
  return value
}

const createVirtualInputElement = (value: string): HTMLInputElement => {
  const input = document.createElement('input')
  input.setAttribute('type', 'text')
  input.setAttribute('value', value)
  return input
}

const maskFormatter = (field: Field, value: string): string => {
  if (value === '' || value === null) {
    return ''
  }

  const element = createVirtualInputElement(value)
  const inputMask = createInputMask(field.field_type)
  if (inputMask == null) {
    return value
  }
  inputMask.mask(element)
  return element.value
}

const createInputMask = (fieldType: string): Inputmask.Instance | undefined => {
  switch (fieldType) {
    case 'email':
      return Inputmask({ alias: 'email' })
    case 'percentage':
      return Inputmask({ alias: 'percentage', digits: 2 })
    case 'currency':
      return Inputmask({ alias: 'currency', prefix: '$' })
    case 'number':
      return Inputmask({ alias: 'numeric' })
    case 'tel':
      return Inputmask({ mask: '(999) 999-9999', greedy: false, placeholder: '_' })
    case 'ein':
      return Inputmask({ mask: '99-9999999', greedy: false, placeholder: '_' })
    case 'ssn':
      return Inputmask({ alias: 'ssn', rightAlign: false })
  }
}

export const formatField = (field: Field, value: string): string => {
  const formatter = fieldFormatter(field)
  if (formatter !== null && formatter !== undefined) {
    return formatter(value)
  }
  return value
}
