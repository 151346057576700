import { Suspense } from 'react'

import { AppLoader } from 'components/app-loader'
import { useTenantCustomization } from 'hooks/useTenantCustomization'
import { useTenant } from 'hooks/useTenant'
import { useUser } from 'hooks/useUser'
import { CustomizationProvider, TenantProvider, UserProvider } from 'providers'

const GlobalDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: user } = useUser()
  const { data: tenant } = useTenant()
  const { data: customization } = useTenantCustomization()

  if (user === undefined || tenant === undefined || customization === undefined)
    return <AppLoader />

  return (
    <Suspense fallback={<AppLoader />}>
      <UserProvider user={user}>
        <TenantProvider tenant={tenant}>
          <CustomizationProvider customization={customization}>{children}</CustomizationProvider>
        </TenantProvider>
      </UserProvider>
    </Suspense>
  )
}

export { GlobalDataProvider }
