import { createContext, useContext, useState } from 'react'
import type { Disclaimer } from 'types'

interface Context {
  disclaimer: Disclaimer
  agreedToDisclaimerTerms: boolean
  setagreedToDisclaimerTerms: (value: boolean) => void
}

interface Provider {
  children: React.ReactNode
  disclaimer: Disclaimer
}

const DisclaimerContext = createContext<Context | null>(null)

export const DisclaimerProvider = ({ children, disclaimer }: Provider): React.ReactElement => {
  const [agreedToDisclaimerTerms, setagreedToDisclaimerTerms] = useState(false)

  const contextValue = {
    agreedToDisclaimerTerms,
    disclaimer,
    setagreedToDisclaimerTerms
  }

  return <DisclaimerContext.Provider value={contextValue}>{children}</DisclaimerContext.Provider>
}

export const useDisclaimerContext = (): Context => {
  const context = useContext(DisclaimerContext)

  if (context == null) {
    throw new Error('useDisclaimerContext must be used within a DisclaimerProvider')
  }

  return context
}
