import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import SectionContext from '../Context/Section.context'
import { StepGroup } from '../Group/StepGroup'
import { getFormDefaultValues } from './step.helper'
import { Button, Group } from '@mantine/core'
import { setItem } from '../../services/cache'
import { StepFormWrapper } from './StepForm.styles'

export const StepForm = ({
  groups,
  nextStep,
  prevStep,
  initialFormValues,
  stepSize,
  currentStep
}) => {
  const { dealId } = useContext(SectionContext)

  const key = `${dealId}_form`
  const initialValues = dealId ? initialFormValues.deal[0] : {}
  const dealInitialValues = getFormDefaultValues(groups, initialValues)

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
    clearErrors
  } = useForm({
    defaultValues: { deal: [dealInitialValues] },
    mode: 'onBlur'
  })

  const saveFormValues = async () => {
    await setItem(key, JSON.stringify(getValues()))
  }

  const handlePrevStep = async () => {
    await saveFormValues()
    prevStep()
  }

  const handleNextStep = async () => {
    await saveFormValues()

    const isValid = await trigger()
    if (!isValid) {
      return
    }
    nextStep()
  }

  const onError = (errors, e) => {
    console.log(errors, e)
  }

  const onSubmit = async (data, e) => {
    e.preventDefault()
    await saveFormValues()
    if (currentStep === stepSize - 1) {
    }
  }

  const preventEnterKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault()
  }

  return (
    <StepFormWrapper>
      <form onSubmit={handleSubmit(onSubmit, onError)} onKeyDown={(e) => preventEnterKeyDown(e)}>
        {groups.map((group, index) => {
          return (
            <StepGroup
              group={group}
              key={`${group.id}${group.name}${index}`}
              fields={group.fields}
              model={group.model}
              index={index}
              control={control}
              register={register}
              subtitle={group.name}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              clearErrors={clearErrors}
              trigger={trigger}
            />
          )
        })}

        <div>
          <Group position="center" my="40px">
            <Button onClick={handlePrevStep} className="navigation_button">
              Previous
            </Button>
            <Button onClick={handleNextStep} className="navigation_button">
              Next
            </Button>
          </Group>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </StepFormWrapper>
  )
}
