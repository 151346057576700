import styled from 'styled-components'

export const RadioUploadWrapper = styled.div`
  width: 100%;

  .fields {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }

  .upload_option {
    width: 100%;
  }

  .form-row-error {
    font-size: 14px;
    font-weight: 600;
    color: #d93025;
    margin-left: 5px;
  }

  .required-indicator {
    display: inline;
    padding-left: 2px;
    color: #f44336;
  }

  .radio_option {
    width: 100%;
    margin-left: 10px;

    input[type='radio'] {
      display: none;

      &:checked {
        & + div {
          border-color: rgb(47, 218, 153);
        }
      }
    }
  }
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 42px;
  border: 1px solid #cfd1d2;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  transition: border 0.2s ease-in-out;
  white-space: nowrap;

  &:hover {
    border-color: #393f44;
  }
`
