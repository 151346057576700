import { tenantPrefix } from './tenant'

export const baseApiURL = (): string => {
  const baseUrl = import.meta.env.VITE_API_BASE_URL
  const apiPort = import.meta.env.VITE_API_PORT

  return `${baseUrl}:${apiPort}/underwrite`
}

export const basePlatformApiURL = (): string => {
  const baseUrl = import.meta.env.VITE_PLATFORM_API_BASE_URL
  const apiPort = import.meta.env.VITE_PLATFORM_API_PORT

  return `${baseUrl}:${apiPort}`
}

export const reactPlatformURL = (): string => {
  const tenantToken = tenantPrefix()
  const reactPlatformURL = import.meta.env.VITE_PLATFORM_REACT_URL

  return reactPlatformURL.replace('{{tenantToken}}', tenantToken)
}

export const awsImageURL = (path: string, tenantToken?: string): string => {
  const tenant = tenantToken ?? tenantPrefix()
  const awsImageBaseURL = import.meta.env.VITE_IMAGES_BASE_URL

  return `${awsImageBaseURL}/${tenant}/${path}`
}

export const appendPathToURL = (url: string, path: string): string => {
  return new URL(path, url).toString()
}
