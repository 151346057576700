import { NativeSelect } from '@mantine/core'
import { type FieldError, useController } from 'react-hook-form'
import { type Field, type ReactHookFormControl } from '../Form/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

export interface SelectProps extends ReactHookFormControl {
  renderLabel: (field: Field) => JSX.Element
  placeholder?: string
}

const errorMessage = (error: FieldError | undefined): JSX.Element | null => {
  if (error?.message == null) return null
  return (
    <span>
      <FontAwesomeIcon icon={faCircleExclamation} color={'#d93025'} />
      <span style={{ color: '#d93025', fontWeight: 600, marginLeft: '5px' }}>{error?.message}</span>
    </span>
  )
}

export const Select = ({
  field,
  fieldName,
  control,
  renderLabel,
  placeholder
}: SelectProps): JSX.Element => {
  const elements = field.options.map((option) => {
    return { value: option.value, label: option.label }
  })

  const {
    field: controllerField,
    fieldState: { error }
  } = useController({
    name: fieldName,
    control,
    rules: { required: { value: field.required, message: 'Field is required' } },
    defaultValue: ''
  })

  const placeholderLabel: string = placeholder !== undefined ? placeholder : 'Choose one'
  const items = [{ label: placeholderLabel, value: '' }, ...elements]
  return (
    <NativeSelect
      {...controllerField}
      data={items}
      label={renderLabel(field)}
      withAsterisk={field.required}
      size="md"
      error={errorMessage(error)}
    />
  )
}
