import React from 'react'
import { Skeleton } from '@mantine/core'

export const SkeletonForm: React.FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} data-testid="skeleton">
      <Skeleton height={20} radius="sm" style={{ marginTop: '10px' }} width={'30%'} />

      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px' }}
      >
        <Skeleton height={45} circle mb="sm" />
        <Skeleton height={3} radius="sm" width={'15%'} style={{ marginLeft: '10px' }} />
        <Skeleton height={45} circle mb="sm" style={{ marginLeft: '5%' }} />
        <Skeleton height={3} radius="sm" width={'15%'} style={{ marginLeft: '10px' }} />
        <Skeleton height={45} circle mb="sm" style={{ marginLeft: '5%' }} />
        <Skeleton height={3} radius="sm" width={'15%'} style={{ marginLeft: '10px' }} />
        <Skeleton height={45} circle mb="sm" style={{ marginLeft: '5%' }} />
        <Skeleton height={3} radius="sm" width={'15%'} style={{ marginLeft: '10px' }} />
      </div>

      <Skeleton height={10} radius="sm" style={{ marginTop: '10px' }} width={'20%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '30px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
      <Skeleton height={30} radius="sm" style={{ marginTop: '10px' }} width={'100%'} />
    </div>
  )
}
