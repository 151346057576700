import styled from 'styled-components'

export const SectionWrapper = styled.div`
  .section-title {
    color: #393f44;
    word-wrap: normal;
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;
  }

  .contact-email {
    color: #9eaeb5;
    word-wrap: normal;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.3px;
  }

  .section-content {
    margin-top: 40px;
  }
`
