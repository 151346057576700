import {
  createStyles,
  Container,
  useMantineTheme,
  ActionIcon,
  Group,
  Anchor,
  Text
} from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { getContrastColor } from 'utils'

const useStyles = createStyles(
  (
    theme,
    { footerBackground, footerColor }: { footerBackground: string; footerColor: string }
  ) => ({
    footer: {
      marginTop: 'auto',
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
      paddingTop: '20px',
      backgroundColor: footerBackground,
      img: {
        maxHeight: '100px',
        maxWidth: '350px'
      }
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${theme.spacing.lg}px ${theme.spacing.lg}px`,

      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column'
      }
    },

    links: {
      [theme.fn.smallerThan('xs')]: {
        marginTop: theme.spacing.md
      },
      a: {
        color: footerColor
      }
    },
    social: {},
    afterFooter: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing.xl,
      paddingTop: theme.spacing.xl,
      paddingBottom: theme.spacing.xl,
      borderTop: '1px solid #000',
      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column'
      }
    }
  })
)

interface FooterCenteredProps {
  links: Array<{ link: string; label: string }>
  socialLinks: Array<{ link: string; label: string }>
}

export const Footer = ({ links, socialLinks }: FooterCenteredProps): JSX.Element => {
  const theme = useMantineTheme()

  const { classes } = useStyles({
    footerBackground: theme.colors.background[6],
    footerColor: getContrastColor(theme.colors.background[6])
  })

  const logoUrl = theme.other.logo?.length > 0 ? theme.other.logo : '/fv_logo.png'

  const items = links.map((link) => (
    <Anchor<'a'>
      key={link.label}
      href={link.link}
      sx={{ lineHeight: 1 }}
      onClick={(event) => {
        event.preventDefault()
      }}
      size="sm"
    >
      {link.label}
    </Anchor>
  ))

  const socialItems = socialLinks.map((link) => (
    <ActionIcon key={link.label}>
      <FontAwesomeIcon icon={faTwitterSquare} color="#FFFFFF" />
    </ActionIcon>
  ))

  const tenantUrl = theme.other.tenantUrl ?? 'https://xenplatforms.com/'
  return (
    <footer className={classes.footer}>
      <Container className={classes.inner} size="lg">
        <div>
          <a href={tenantUrl}>
            <img src={logoUrl} alt="tenant_logo" />
          </a>
        </div>
        <Group className={classes.links}>{items}</Group>
        <Group spacing={0} className={classes.social} position="right" noWrap>
          {socialItems}
        </Group>
      </Container>
      <Container className={classes.afterFooter}>
        <Text color="dimmed" size="sm">
          Copyright © {new Date().getFullYear()}, Xen, Inc. | Made in San Francisco
        </Text>
      </Container>
    </footer>
  )
}
