import { MantineProvider } from '@mantine/core'
import { createMantineThemeFromCustomization } from 'utils'

import { type MenuLink } from 'types'
import { useCustomizationContext } from 'providers'
declare module '@mantine/core' {
  export interface MantineThemeOther {
    favicon: string
    logo: string
    tenantUrl: string
    menuLinks?: MenuLink[]
    urlFacebook?: string
    urlLinkedin?: string
    urlXTwitter?: string
  }
}

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const customization = useCustomizationContext()
  const mantineTheme = createMantineThemeFromCustomization(customization)

  return (
    <MantineProvider withCSSVariables withGlobalStyles withNormalizeCSS theme={mantineTheme}>
      {children}
    </MantineProvider>
  )
}

export { ThemeProvider }
