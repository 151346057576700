import React, { useState, useEffect } from 'react'
import { RadioWrapper, OptionWrapper } from './Radio.styles'
import { ErrorMessage } from '@hookform/error-message'
import { inputToRender } from '@form/Group/StepGroup'

export const FollowingQuestionRadio = ({
  field,
  fieldName,
  register,
  setValue,
  buttonWidth,
  errors,
  clearErrors,
  getValues,
  control
}) => {
  const [radioButtonChecked, setRadioButtonChecked] = useState('')
  const [showFollowingQuestion, setShowFollowingQuestion] = useState(false)

  const { onChange, name, ref } = register(fieldName, { required: field.required })

  useEffect(() => {
    const fieldValue = getValues(fieldName)
    if (fieldValue !== null) {
      setRadioButtonChecked(fieldValue)
      fieldValue === 'yes' ? setShowFollowingQuestion(true) : setShowFollowingQuestion(false)
    }
  }, [])

  const handleSelectOption = (value) => {
    setRadioButtonChecked(value)
    setValue(fieldName, value)
    clearErrors(fieldName)
    value === 'yes' ? setShowFollowingQuestion(true) : setShowFollowingQuestion(false)
  }

  return (
    <div>
      <RadioWrapper buttonWidth={buttonWidth}>
        <div>
          <label className={'label'}>{field.field_label}</label>
          {field.required && <span className="required-indicator">*</span>}
        </div>
        <div className="fields">
          {field.options.map((option) => {
            return (
              <div className="radio_option" key={option.label}>
                <input
                  type="radio"
                  ref={ref}
                  name={name}
                  value={option.value}
                  checked={radioButtonChecked === option.value}
                  onChange={onChange}
                />

                <OptionWrapper onClick={() => handleSelectOption(option.value)}>
                  {option.label}
                </OptionWrapper>
              </div>
            )
          })}
        </div>
        <ErrorMessage
          errors={errors}
          name={fieldName}
          render={(_params) => <div className={'form-row-error'}>Field is required</div>}
        />
      </RadioWrapper>

      {showFollowingQuestion && (
        <div className="field_container">
          {inputToRender({
            field: field.following_question,
            index: field.index,
            register,
            setValue,
            errors,
            clearErrors,
            getValues,
            control
          })}
        </div>
      )}
    </div>
  )
}
