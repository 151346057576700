export const regexPattern = {
  /** $1000.00 */
  currency: /^\$?((\d+(\.\d{2})?)|(\.\d{2}))$/,
  /** 2022-12-31 */
  date: /^(?:\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01]))$/,
  /** 01-1234567 or 123456789 */
  ein: /^\d{2}-\d{7}$|^\d{9}$/,
  /** user@email.com */
  email: /^(?!\.)(?!.*\.\.)[a-zA-Z0-9._!#$%&'*+\-/=?^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  /** 12345.6789 */
  number: /^(?!^\.+$)(?=\d|\.\d)\d*(?:\.\d+)?$/,
  /** 70.25 */
  percentage: /^(100(?:\.00?)?|\d{1,2}(?:\.\d{1,2})?|\.\d{1,2})$/,
  /** 12-345-6789 or 123456789 */
  ssn: /^\d{3}-\d{2}-\d{4}$|^\d{9}$/,
  /** (123) 456-7890 or 1234567890 */
  tel: /^(?:\(\d{3}\)\s?)?(\d{10}|\d{3}-\d{4})$/
} as const
