import { Alert, Text, Title } from '@mantine/core'

export const Error = ({
  message = 'Unknown error',
  title = 'Error'
}: {
  message?: string
  title?: string
}): JSX.Element => {
  return (
    <Alert variant="light" color="red" title={`⚠️ ${message}`}>
      <Title>{title}</Title>
      <Text size="lg">
        An unexpected error has occurred. Contact tech support at{' '}
        <a href="mailto:support@xenplatforms.com">support@xenplatforms.com</a>.
      </Text>
    </Alert>
  )
}
