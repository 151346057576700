import { useDisclosure } from '@mantine/hooks'
import { ChangePasswordModal } from 'components/ChangePassword/ChangePasswordModal'

interface UseChangePasswordModal {
  opened: boolean
  open: () => void
  close: () => void
  ChangePasswordModal: () => JSX.Element
}

export const useChangePasswordModal = (): UseChangePasswordModal => {
  const [opened, { open, close }] = useDisclosure()

  return {
    opened,
    open,
    close,
    ChangePasswordModal: () => <ChangePasswordModal opened={opened} close={close} />
  }
}
