import { Layout } from 'components/layout'
import { MonitoringContext } from 'components/monitoring-context'
import { FeaturesProvider, GlobalDataProvider, QueryProvider, ThemeProvider } from 'providers'

/**
 * Root route component that wraps every other nested route component.
 * 1. QueryProvider: Provides the react-query functionality to children.
 * 2. FeaturesProvider: Fetches features from Flipper and provides them as context to children.
 * 3. GlobalDataProvider: Fetches user, tenant, and customization data and provides it as context to children.
 *    Also handles global loading and errors states if any of the data fetching fails.
 * 4. MonitoringContext: Adds user and tenant context to Datadog RUM (relies on data fetched in GlobalDataProvider).
 * 5. ThemeProvider: Provides the custom tenant theme context to children and sets Mantine theme.
 * 6. Layout: Wraps router Outlet (i.e. all child routes) with the same UI layout.
 */
export const Root: React.FC = () => {
  return (
    <QueryProvider>
      <FeaturesProvider>
        <GlobalDataProvider>
          <MonitoringContext>
            <ThemeProvider>
              <Layout />
            </ThemeProvider>
          </MonitoringContext>
        </GlobalDataProvider>
      </FeaturesProvider>
    </QueryProvider>
  )
}
