import { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { TextInput } from '@mantine/core'
import { useId } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import type { Field, ReactHookFormControl, ReactHookFormValues } from 'Form/types'
import Inputmask from 'inputmask'
import { regexPattern } from 'Formatters'

export interface InputProps extends ReactHookFormControl {
  setValue: <T extends ReactHookFormValues>(field: string, value: T[string]) => void
  clearErrors: (fieldName: string | undefined) => void
  renderLabel: (field: Field) => JSX.Element
  trigger: (fieldName: string | string[]) => Promise<boolean>
  field: Field
}

interface Attributes {
  min?: string
  max?: string
}

interface Pattern {
  value: RegExp
  message: string
}

interface RequiredValidation {
  value: boolean
  message: string
}

interface Rules {
  required: RequiredValidation
  pattern?: Pattern
  validate?: (value: string) => string | boolean
}

export const Input = ({
  field,
  fieldName,
  control,
  setValue,
  clearErrors,
  renderLabel,
  trigger
}: InputProps): JSX.Element => {
  const [validationAttributes, setValidationAttributes] = useState<Attributes>({})
  const [fieldType, setFieldType] = useState('text')
  const [validationPattern, setValidationPattern] = useState<Pattern>()

  const validateDate = (value: string): string | boolean => {
    const date = new Date(value)
    if (
      validationAttributes.min !== null &&
      validationAttributes.min !== undefined &&
      date < new Date(validationAttributes.min)
    ) {
      return `Date must be after ${validationAttributes.min}`
    }
    if (
      validationAttributes.max !== null &&
      validationAttributes.max !== undefined &&
      date > new Date(validationAttributes.max)
    ) {
      return `Date must be before ${validationAttributes.max}`
    }
    return true
  }

  const rules = (field: Field, pattern?: Pattern): Rules => {
    const baseRules = { required: { value: field.required, message: 'Field is required' } }
    if (field.field_type === 'date') {
      return {
        ...baseRules,
        pattern: { value: /^\d{4}-\d{2}-\d{2}$/, message: 'Invalid date' },
        validate: validateDate
      }
    }
    if (pattern !== null && pattern !== undefined) {
      return { ...baseRules, pattern }
    }
    return baseRules
  }

  const {
    field: { onChange, name, ref, value },
    fieldState: { error }
  } = useController<ReactHookFormValues>({
    name: fieldName,
    control,
    rules: rules(field, validationPattern),
    defaultValue:
      field.default_value !== null && field.default_value !== undefined ? field.default_value : ''
  })

  const id = useId()

  const defineDateValidations = (field: Field): void => {
    const attributes: Attributes = {}
    const now = new Date()
    let year = now.getFullYear()
    const month = now.getMonth() + 1
    const day = now.getDate()

    const zeroLedMonth = month < 10 ? `0${month}` : `${month}`
    const zeroLedDayday = day < 10 ? `0${day}` : `${day}`

    if (field.min === 0) {
      attributes.min = `${year}-${zeroLedMonth}-${zeroLedDayday}`
    }

    if (field.max !== null && field.max !== undefined) {
      year = year + field.max
      attributes.max = `${year}-${zeroLedMonth}-${zeroLedDayday}`
    }
    setValidationAttributes(attributes)
  }

  useEffect((): void => {
    const selector = document.getElementById(id)
    if (selector !== null) {
      switch (field.field_type) {
        case 'email':
          Inputmask({ alias: 'email', placeholder: '' }).mask(selector)
          setValidationPattern({ value: regexPattern.email, message: 'Invalid email address' })
          break
        case 'percentage':
          Inputmask({ alias: 'percentage', digits: 2, rightAlign: false, autoUnmask: true }).mask(
            selector
          )
          setValidationPattern({
            value: regexPattern.percentage,
            message: 'Invalid percentage value'
          })
          break
        case 'currency':
          Inputmask({
            alias: 'currency',
            prefix: '$',
            rightAlign: false,
            digitsOptional: true,
            autoUnmask: true
          }).mask(selector)
          setValidationPattern({ value: regexPattern.currency, message: 'Invalid currency' })
          break
        case 'number':
          Inputmask({ alias: 'numeric', rightAlign: false, placeholder: '' }).mask(selector)
          setValidationPattern({ value: regexPattern.number, message: 'Invalid number' })
          break
        case 'tel':
          Inputmask({ mask: '(999) 999-9999', greedy: false, placeholder: '_' }).mask(selector)
          setValidationPattern({
            value: regexPattern.tel,
            message: 'Invalid telephone number'
          })
          break
        case 'ein':
          Inputmask({ mask: '99-9999999', greedy: false, placeholder: '_' }).mask(selector)
          setValidationPattern({
            value: regexPattern.ein,
            message: 'Invalid EIN'
          })
          break
        case 'ssn':
          Inputmask({ mask: '999-99-9999', placeholder: '_' }).mask(selector)
          setValidationPattern({ value: regexPattern.ssn, message: 'Invalid SSN' })
          break
        case 'date':
          setValidationPattern({ value: regexPattern.date, message: 'Invalid date' })
          defineDateValidations(field)
          setFieldType(field.field_type)
          break
        case 'text':
          setFieldType(field.field_type)
          break
        default:
          setFieldType(field.field_type)
      }
    }
  }, [])

  const handleOnblur = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    let value: string | null = null
    if (e?.target !== null) {
      if (e.target.inputmask != null) {
        value = e.target.inputmask.unmaskedvalue()
      } else {
        value = e.target.value
      }
      trigger(fieldName).catch((err) => {
        console.log(err)
      })
      setValue(e.target.name, value)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrors(fieldName)
    onChange(event)
  }

  return (
    <TextInput
      size="md"
      id={id}
      onChange={handleChange}
      onBlur={handleOnblur}
      label={renderLabel(field)}
      type={fieldType}
      withAsterisk={field.required}
      placeholder={field.placeholder}
      error={
        !(error == null) && (
          <span data-testid="input-error">
            <FontAwesomeIcon icon={faCircleExclamation} color={'#d93025'} />
            <span style={{ color: '#d93025', fontWeight: 600, marginLeft: '5px' }}>
              {error.message}
            </span>
          </span>
        )
      }
      name={name}
      value={value}
      ref={ref}
      data-testid={field.field_label}
      {...validationAttributes}
    />
  )
}
