import { basePlatformApiURL } from 'utils/UrlHelper'
import { tenantPrefix } from 'utils'
import camelcaseKeys from 'camelcase-keys'
import type { SignatureRequest, SignatureRequestResponse, SignatureRequestURL } from 'types'

export const getSignatureRequests = async (identifier: string): Promise<SignatureRequest[]> => {
  const fetchErrorMessage = 'Error fetching signature requests'
  try {
    const headers = {
      'Content-Type': 'application/json',
      'session-token': identifier,
      'tenant-token': tenantPrefix()
    }
    return await fetch(`${basePlatformApiURL()}/v2/signature_requests/pending`, {
      method: 'GET',
      headers
    }).then(async (response) => {
      if (response.ok) {
        const json = (await response.json()) as SignatureRequestResponse[]
        return camelcaseKeys(json, { deep: true }) as SignatureRequest[]
      }

      throw Error(fetchErrorMessage)
    })
  } catch (_error) {
    throw Error(fetchErrorMessage)
  }
}

export const getSignatureRequestURL = async (
  globalKey: string,
  identifier: string
): Promise<SignatureRequestURL> => {
  const fetchErrorMessage = 'Error fetching signature request URL'
  try {
    const headers = {
      'Content-Type': 'application/json',
      'session-token': identifier,
      'tenant-token': tenantPrefix()
    }
    return await fetch(`${basePlatformApiURL()}/v2/signature_requests/signature_url/${globalKey}`, {
      method: 'GET',
      headers
    }).then(async (response) => {
      if (response.ok) {
        return camelcaseKeys(await response.json(), { deep: true }) as SignatureRequestURL
      }

      throw Error(fetchErrorMessage)
    })
  } catch (_error) {
    throw Error(fetchErrorMessage)
  }
}
