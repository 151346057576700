import { basePlatformApiURL } from 'utils/UrlHelper'
import { removeUserFromMonitoringContext, tenantPrefix } from 'utils'
import camelcaseKeys from 'camelcase-keys'
import type { User, UserResponse } from 'types'

export const getUser = async (identifier: string): Promise<User> => {
  const fetchErrorMessage = 'Error fetching user'
  try {
    const headers = {
      'Content-Type': 'application/json',
      'session-token': identifier,
      'tenant-token': tenantPrefix()
    }
    return await fetch(`${basePlatformApiURL()}/login/logged_in_user_info`, {
      method: 'GET',
      headers
    }).then(async (response) => {
      if (response.ok) {
        const json = (await response.json()) as UserResponse
        return camelcaseKeys(json, { deep: true }) as User
      }

      throw Error(fetchErrorMessage)
    })
  } catch (_error) {
    throw Error(fetchErrorMessage)
  }
}

export const isUser = (data: unknown): data is User => {
  return typeof data === 'object' && data !== null && 'firstName' in data
}

/**
 * Invalidates an existing user session.
 *
 * @param {string} sessionToken   - The session identifier
 *
 * @returns {boolean} - If the session was either invalidated or not
 *
 * @throws {Error} - An error message
 */
export const invalidateUserSession = async (sessionToken: string): Promise<boolean> => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Session-Token': sessionToken,
      'Tenant-Token': tenantPrefix()
    }
    const body = JSON.stringify({
      session_token: sessionToken
    })

    const url = `${basePlatformApiURL()}/login/invalidate`

    const response = await fetch(url, { method: 'PUT', headers, body })
    if (!response.ok) return false

    removeUserFromMonitoringContext()
    return true
  } catch (_error) {
    return false
  }
}
