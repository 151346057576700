import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useDealIdentifier } from './useDealIdentifier'
import { getSignatureRequests } from 'services/signatureRequest'
import { type SignatureRequest } from 'types'

export const useSignatureRequests = (): UseQueryResult<SignatureRequest[]> => {
  const identifierToken = useDealIdentifier() ?? ''

  return useQuery<SignatureRequest[], unknown>({
    queryKey: makeSignatureRequestQueryKey(identifierToken),
    queryFn: async () => await getSignatureRequests(identifierToken),
    enabled: identifierToken !== '',
    staleTime: 1000 * 6 // six seconds
  })
}

export const makeSignatureRequestQueryKey = (identifier: string): string[] => [
  'signatureRequest',
  identifier
]
