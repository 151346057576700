import { useStyles, HEADER_HEIGHT } from './header.styles'
import { getContrastColor } from 'utils'
import { Header, Container, useMantineTheme } from '@mantine/core'
import SignatureRequests from 'components/SignatureRequests/SignatureRequests'
import EllipsisMenu from './EllipsisMenu/EllipsisMenu'
import { useTenantContext, useUserContext } from 'providers'

export const HeaderResponsive = (): JSX.Element => {
  const user = useUserContext()
  const tenant = useTenantContext()
  const {
    colors: { background },
    other: { logo, tenantUrl }
  } = useMantineTheme()
  const { classes } = useStyles({
    headerBackgroundColor: background[6],
    headerColor: getContrastColor(background[6])
  })

  return (
    <Header height={HEADER_HEIGHT} className={classes.root}>
      <Container size="lg">
        <div className={classes.header}>
          <a href={tenantUrl}>
            <img alt={tenant.organizationName} src={logo} className={classes.img} />
          </a>
          <div className={classes.headerRight}>
            <SignatureRequests />
            <span className={classes.emailHeader}>{user.emailAddress}</span>
            <EllipsisMenu />
          </div>
        </div>
      </Container>
    </Header>
  )
}
