import { useState } from 'react'

export type SignerModalState = 'alert' | 'list' | 'document' | 'closed'

interface UseSignerModalReturn {
  state: SignerModalState
  setSignerModalState: (state: SignerModalState) => void
}

const useSignerModal = (): UseSignerModalReturn => {
  const [state, setState] = useState<SignerModalState>('closed')

  const setSignerModalState = (state: SignerModalState): void => {
    setState(state)
  }

  return {
    state,
    setSignerModalState
  }
}

export default useSignerModal
