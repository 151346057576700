import { useContext, useState, useEffect } from 'react'

import { getItem } from '../../services/cache'

import { StepForm } from '../Step/StepForm'
import { Document } from '../Document/Document'
import SectionContext from '../Context/Section.context'

import { type Step } from '../types'
import { type Deal } from '../../services/deals'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Group, Stack, Text } from '@mantine/core'

export interface SectionStepProps {
  step: Step
  stepSize: number
  nextStep: () => void
  prevStep: () => void
  currentStep: number
}

export const SectionStep = ({
  step,
  stepSize,
  nextStep,
  prevStep,
  currentStep
}: SectionStepProps): JSX.Element | null => {
  const { dealId } = useContext(SectionContext)
  const [initialFormValues, setInitialFormValues] = useState<Deal | null>(null)

  const documentToRender = (step: Step): JSX.Element | null => {
    const { documents } = step

    if (documents.length <= 0) return null

    return (
      <>
        {documents.map((document) => (
          <Document document={document} key={document.name} />
        ))}
      </>
    )
  }

  useEffect(() => {
    const getInitialFormValues = async (): Promise<Deal> => {
      const key = `${dealId ?? ''}_form`
      return await getItem<Deal>(key)
    }

    getInitialFormValues()
      .then((value) => {
        setInitialFormValues(value)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  if (initialFormValues === null) return null

  const { name, groups, subtitle, tooltip } = step

  return (
    <>
      <Stack spacing={3} sx={(theme) => ({ marginBlock: theme.spacing.xl })}>
        <Group spacing={2}>
          <Text sx={{ fontSize: '1.5rem', letterSpacing: 0.3, fontWeight: 'bold' }}>{name}</Text>
          {tooltip !== null && <Tooltip tip={tooltip} />}
        </Group>
        {subtitle != null && <Text>{subtitle}</Text>}
      </Stack>
      <StepForm
        groups={groups}
        stepSize={stepSize}
        nextStep={nextStep}
        prevStep={prevStep}
        currentStep={currentStep}
        initialFormValues={initialFormValues}
      />
      {documentToRender(step)}
    </>
  )
}
