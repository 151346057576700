import React from 'react'

import ReactDOM from 'react-dom/client'

import './main.css'
import App from './App'
import { AppBoundary } from 'components/app-boundary'
import { startMonitoring } from 'utils'

const version = import.meta.env.VITE_DATADOG_VERSION
const DATADOG_APP_ID = import.meta.env.VITE_DATADOG_APP_ID
const shouldInitDatadog = typeof DATADOG_APP_ID === 'string' && DATADOG_APP_ID.length > 0
const env = import.meta.env.MODE

if (shouldInitDatadog) {
  startMonitoring(DATADOG_APP_ID, env, version)
}

const Main: React.FC = () => (
  <React.StrictMode>
    <AppBoundary>
      <App />
    </AppBoundary>
  </React.StrictMode>
)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<Main />)
