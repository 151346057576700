import { useRouteError } from 'react-router-dom'
import { Error } from 'components/error'

export const isError = (data: unknown): data is Error => {
  return typeof data === 'object' && data !== null && 'message' in data
}

export const RouterError = ({
  message = '',
  title
}: {
  message?: string
  title?: string
}): JSX.Element => {
  const routerError = useRouteError()

  let errorMessage = message
  if (isError(routerError)) errorMessage = `${errorMessage} - ${routerError.message}`

  return <Error message={errorMessage} title={title} />
}
