import { createStyles } from '@mantine/core'

export const HEADER_HEIGHT = 62

export const useStyles = createStyles(() => ({
  signOutButton: {
    width: '100%',
    fontSize: '13px',
    minWidth: '60px',
    padding: '5px 12px',
    textAlign: 'center',
    justifyContent: 'right'
  }
}))
