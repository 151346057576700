import { Modal, PasswordInput, Button, Stack } from '@mantine/core'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { resetPassword } from 'services/resetPassword'
import isStrongPassword from 'validator/lib/isStrongPassword'
import isEmpty from 'validator/lib/isEmpty'
import { notifications } from '@mantine/notifications'
import { useUserContext } from 'providers'

interface ChangePasswordForm {
  randPassword: string
  newPassword: string
  confirmPassword: string
}

export const cognitoPasswordValid = (password: string | null): boolean => {
  if (password == null || isEmpty(password)) return false

  return isStrongPassword(password, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1
  })
}

export const ChangePasswordModal = ({
  opened,
  close
}: {
  opened: boolean
  close: () => void
}): JSX.Element => {
  const user = useUserContext()
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ChangePasswordForm>()
  const onSubmit: SubmitHandler<ChangePasswordForm> = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: 'Passwords do not match'
      })
      return
    }
    if (!cognitoPasswordValid(data.newPassword)) {
      setError('newPassword', {
        type: 'manual',
        message:
          'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter, and one special character'
      })
      return
    }
    if (user?.emailAddress === undefined) {
      console.error('User email address is undefined')
      return
    }
    try {
      await resetPassword({
        userEmail: user?.emailAddress,
        randPassword: data.randPassword,
        newPassword: data.newPassword
      })
      notifications.show({
        title: 'Success',
        message: 'Your password has been updated',
        color: 'green'
      })
      close()
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'WrongPassword') {
          setError('randPassword', {
            type: 'server',
            message: 'Wrong password'
          })
          return
        }
        console.error(error.message)
      }
      console.error('Error updating password')
    }
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    void handleSubmit(onSubmit)()
  }

  return (
    <Modal
      opened={opened}
      onClose={() => {
        close()
      }}
      title="Change Password"
      size="md"
      padding="md"
      data-testid="change-password-modal"
    >
      <div>
        <form onSubmit={handleFormSubmit}>
          <Stack>
            <PasswordInput
              withAsterisk
              label="Current Password"
              placeholder="Current Password"
              {...register('randPassword')}
              error={errors?.randPassword?.message}
            />
            <PasswordInput
              withAsterisk
              label="New Password"
              placeholder="New Password"
              {...register('newPassword')}
              error={errors?.newPassword?.message}
            />
            <PasswordInput
              withAsterisk
              label="Confirm Password"
              placeholder="Confirm Password"
              {...register('confirmPassword')}
              error={errors?.confirmPassword?.message}
            />
            <Button type="submit">Save</Button>
          </Stack>
        </form>
      </div>
    </Modal>
  )
}
