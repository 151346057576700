import React, { type Dispatch, createContext, useReducer } from 'react'
import { storage } from 'utils/storage'

enum ActionTypes {
  SET_SECTION_VALUES,
  SET_CURRENT_STEP
}

interface Action {
  type: ActionTypes
  state: Partial<SectionState>
}

type CurrentStepSetter = (dispatch: Dispatch<Action>, currentStep: number) => void
type InitialValuesSetter = (
  dispatch: Dispatch<Action>,
  initialValues: Partial<SectionState>
) => void
type SectionContextReducer = (state: SectionState, action: Action) => SectionState

export interface SectionState {
  currentStep: number
  dealId: string | null
  setInitialValues?: InitialValuesSetter
  setCurrentStep?: CurrentStepSetter
}

const initialState: SectionState = {
  dealId: null,
  currentStep: 0
}

const SectionContext = createContext({
  ...initialState,
  setInitialValues: (_initialValues: SectionState) => {
    console.warn('setInitialValues has not been configured yet. Wrap in SectionProvider first')
  },
  setCurrentStep: (_currentStep: number) => {
    console.warn('setCurrentStep has not been configured yet. Wrap in SectionProvider first')
  }
})

const setInitialValues: InitialValuesSetter = (dispatch, initialValues) => {
  dispatch({
    type: ActionTypes.SET_SECTION_VALUES,
    state: initialValues
  })
}

const setCurrentStep: CurrentStepSetter = (dispatch, currentStep) => {
  dispatch({
    type: ActionTypes.SET_CURRENT_STEP,
    state: { currentStep }
  })
}

const reducer: SectionContextReducer = (state: SectionState, action: Action): SectionState => {
  switch (action.type) {
    case ActionTypes.SET_SECTION_VALUES:
    case ActionTypes.SET_CURRENT_STEP:
      return { ...state, ...action.state }
    default:
      return { ...state }
  }
}

export const SectionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer<SectionContextReducer>(reducer, initialState)

  const value = {
    ...state,
    setInitialValues: (initialValues: SectionState) => {
      setInitialValues(dispatch, initialValues)
    },
    setCurrentStep: (currentStep: number) => {
      storage.set('currentStep', String(currentStep))
      setCurrentStep(dispatch, currentStep)
    }
  }

  return <SectionContext.Provider value={value}>{children}</SectionContext.Provider>
}

export default SectionContext
