import { type FC } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip as MantineTooltip } from '@mantine/core'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'

interface TooltipProps {
  tip: string
}

export const Tooltip: FC<TooltipProps> = ({ tip }) => (
  <MantineTooltip
    multiline
    width={220}
    withArrow
    transitionProps={{ transition: 'fade' }}
    label={tip}
  >
    <FontAwesomeIcon icon={faCircleQuestion} color="#9EAEB5" style={{ marginLeft: '3px' }} />
  </MantineTooltip>
)
