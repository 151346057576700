import { storage } from 'utils/storage'
import { baseApiURL } from '../utils/UrlHelper'

export const getItem = async <T>(key: string): Promise<T> => {
  const fetchErrorMessage = 'Error getting cached item'
  try {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }

    return await fetch(buildURL(key).toString(), requestOptions).then(async (response) => {
      if (!response.ok) {
        throw new Error(fetchErrorMessage)
      }
      return (await response.json()) as unknown as T
    })
  } catch (error) {
    throw new Error(fetchErrorMessage)
  }
}

export const setItem = async <T>(key: string, value: T): Promise<Response> => {
  const fetchErrorMessage = 'Error setting cached item'
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ value })
    }

    return await fetch(buildURL(key).toString(), requestOptions).then((response) => {
      if (!response.ok) {
        throw new Error(fetchErrorMessage)
      }
      return response
    })
  } catch (error) {
    throw new Error(fetchErrorMessage)
  }
}

const identifier = (): string | null => storage.get('identifier')
const path = (key: string): string => `/api/v1/cache/${key}`

const buildURL = (key: string): URL => {
  const baseUrl: string = baseApiURL() ?? ''
  const url = new URL(baseUrl + path(key))
  const id: string = identifier() ?? ''

  url.searchParams.set('identifier', id)

  return url
}
