import { Alert, Stack, Text, Title } from '@mantine/core'
import { Link } from 'react-router-dom'

export const NotFoundPage: React.FC = () => {
  return (
    <Alert variant="light" color="red" title={404}>
      <Stack spacing="md">
        <Title>Page not found</Title>
        <Text size="lg">
          No page found at this address: <code>{window.location.pathname}</code>
        </Text>
        <Text size="lg">
          <Link to="/">Return to home</Link> or contact tech support at{' '}
          <a href="mailto:support@xenplatforms.com">support@xenplatforms.com</a>.
        </Text>
      </Stack>
    </Alert>
  )
}
