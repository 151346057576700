import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import type { FlipperFeatures } from 'types'
import { tenantPrefix } from 'utils'
import { getFlipperFeatures } from 'services/flipper'
import { useDealIdentifier } from './useDealIdentifier'

export const useGetFlipperFeatures = (): UseQueryResult<FlipperFeatures> => {
  const identifier = useDealIdentifier()
  const tenantToken = tenantPrefix()

  return useQuery<FlipperFeatures>({
    queryKey: ['flipper-features'],
    queryFn: async () => await getFlipperFeatures({ identifier, tenantToken }),
    enabled: identifier !== null
  })
}
