import pluralize from 'pluralize'
/*
 Returns object of default values for set of fields
 Here we also do grouping fields by model
 Output format:
 {
    model_name_1: {
        field_name: field_value,
    },
    model_name_2: {
        field_name: field_value,
    }
 }
*/
export const getFormDefaultValues = (fieldGroups, initialValues) => {
  const rootModel = 'deal'
  fieldGroups.forEach((group) => {
    if (rootModel === group.model) {
      initializeDealDefaultValues(initialValues, group)
    } else {
      initializeModelsDefaultValues(group, initialValues)
    }
  })

  return initialValues
}

const initializeDealDefaultValues = (initialValues, group) => {
  const targetObjectModel = initialValues
  group.fields.forEach((field) => {
    initializeDealDynamicFieldsValues(field, targetObjectModel)
    initializeDealFollowUpFields(field, targetObjectModel)
  })
}

const initializeModelsDefaultValues = (group, initialValues) => {
  const attr = pluralize.plural(group.model)
  let list = initialValues[attr]
  if (list === undefined) {
    initialValues[attr] = []
    list = initialValues[attr]
  }
  if (list && list.length === 0 && (group.repeatable_min === 1 || group.repeatable_min === null)) {
    const obj = {}
    group.fields.forEach((field) => {
      obj[field.field_name] = ''
      field.options
        .filter((option) => option.is_follow_up_option)
        .forEach((option) => {
          option.follow_up_fields.forEach((followUpField) => {
            obj[followUpField.field_name] = ''
          })
        })
    })
    list.push(obj)
  }
}

const initializeDealDynamicFieldsValues = (field, targetObjectModel) => {
  if (field.dynamic && !targetObjectModel[field.field_name]) {
    targetObjectModel[field.field_name] = ''
  }
}

const initializeDealFollowUpFields = (field, targetObjectModel) => {
  field.options
    .filter((option) => option.is_follow_up_option)
    .forEach((option) => {
      option.follow_up_fields.forEach((followUpField) => {
        if (followUpField.dynamic && !targetObjectModel[followUpField.field_name]) {
          targetObjectModel[followUpField.field_name] = ''
        }
      })
    })
}
