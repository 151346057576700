import { Text, Title } from '@mantine/core'

export const SubmittedDeal = (): JSX.Element => {
  return (
    <div>
      <Title>Application Received</Title>
      <Text>We will follow up soon, generally by the next business day.</Text>
    </div>
  )
}
