import { type MantineThemeOverride } from '@mantine/core'
import Color from 'color'

import { type Customization, type HexShadePalette, type HexValue } from 'types'
import { awsImageURL } from 'utils'

// TODO: Look into changing ratio of lightness/saturation/hue/luminance to get better range of colors,
// e.g. start from close to white at the bottom and calculate the rest of the colors from there.
export const generateHexShadePalette = (hex: HexValue): HexShadePalette => {
  const color = Color(hex)

  if (hex === '#FFFFFF') {
    return [
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF',
      '#FFFFFF'
    ] as HexShadePalette
  }

  return [
    color.lighten(1.2).desaturate(0.3).hex(),
    color.lighten(1).desaturate(0.25).hex(),
    color.lighten(0.8).desaturate(0.2).hex(),
    color.lighten(0.6).desaturate(0.15).hex(),
    color.lighten(0.4).desaturate(0.1).hex(),
    color.lighten(0.2).desaturate(0.05).hex(),
    color.hex(),
    color.darken(0.2).saturate(0.05).hex(),
    color.darken(0.4).saturate(0.1).hex(),
    color.darken(0.6).saturate(0.15).hex()
  ] as HexShadePalette
}

export function createMantineThemeFromCustomization(
  customization: Customization
): MantineThemeOverride {
  const mantineTheme: MantineThemeOverride = {
    primaryColor: 'primary',
    primaryShade: 6, // actual primary color is 7th in 0-indexed array (theme.colors.primary[6] or var(--mantine-primary-6))
    colors: {
      primary: generateHexShadePalette(customization.colors.primary),
      secondary: generateHexShadePalette(customization.colors.secondary),
      background: generateHexShadePalette(customization.colors.background)
    },
    headings: { fontFamily: 'Lato' },
    fontFamily: 'Lato',
    other: {
      favicon: awsImageURL('favicon.ico'),
      logo: awsImageURL('header_logo.png'),
      tenantUrl: customization.other.tenantUrl,
      menuLinks: customization.other.menuLinks,
      urlFacebook: customization.other.urlFacebook,
      urlLinkedin: customization.other.urlLinkedin,
      urlXTwitter: customization.other.urlXTwitter
    }
  }
  return mantineTheme
}

export function getContrastColor(
  color: string,
  darkContrastColor = 'var(--mantine-color-primary-6)'
): string {
  if (color === '') return '#FFFFFF'

  return Color(color).isDark() ? '#FFFFFF' : darkContrastColor
}
