import { createContext, useContext } from 'react'
import type { User } from 'types'

interface Provider {
  children: React.ReactNode
  user: User
}

const UserContext = createContext<User | null>(null)

export const UserProvider = ({ children, user }: Provider): React.ReactElement => {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export const useUserContext = (): User => {
  const context = useContext(UserContext)

  if (context === null) {
    throw new Error('useUserContext must be used within a UserProvider')
  }

  return context
}
