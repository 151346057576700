import { useState } from 'react'

import { type BankConnectionError } from 'types'
import { deleteBankConnection } from 'services/bank'

export interface BankConnectionDeletion {
  deleted: boolean
  deleteConnection: () => Promise<void>
  error: null | BankConnectionError
  loading: boolean
}

export const useBankConnectionDelete = (
  identifier: string,
  dealId: number
): BankConnectionDeletion => {
  const [deleted, setDeleted] = useState(false)
  const [error, setError] = useState<null | BankConnectionError>(null)
  const [loading, setLoading] = useState(false)

  const deleteConnection = async (): Promise<void> => {
    setLoading(true)

    try {
      await deleteBankConnection(identifier, dealId)
      setDeleted(true)
    } catch (error) {
      setError({ message: (error as Error).message })
    } finally {
      setLoading(false)
    }
  }

  return { deleted, deleteConnection, error, loading }
}
