import { baseApiURL } from 'utils/UrlHelper'

export const getDeal = async (identifier: string): Promise<DealResponse> => {
  const fetchErrorMessage = 'Error fetching deal'
  try {
    return await fetch(`${baseApiURL()}/api/v1/deals?identifier=${identifier}`).then(
      async (response) => {
        if (response.ok) {
          return (await response.json()) as DealResponse
        }

        throw Error(fetchErrorMessage)
      }
    )
  } catch (_error) {
    throw Error(fetchErrorMessage)
  }
}

export interface DealResponse {
  deal: Deal
}

export interface Deal {
  business_city: string | null
  business_code: string | null
  business_contacts: Array<{
    email?: string
    id?: number
    last_name: string
    name?: string
    phone_number?: string
    title?: string
    [key: string]: unknown
  }>
  business_entity: string | null
  business_name: string | null
  business_details?: unknown[]
  business_officers: unknown[]
  business_phone: string | null
  business_state: string | null
  business_street: string | null
  business_unit: string | null
  created_at: string
  debtors: unknown[]
  document_signers: Array<{
    email: string
    id: number
    name: string
    phone: string
    title: string | null
    type: string
  }>
  documents: Document[]
  due_diligence_documents: unknown[]
  id: number
  sales_person: string | null
  sales_person_id: string | null
  state: string
  tenant_id: number
  updated_at: string
  [key: string]: unknown
}

interface Document {
  name: string
  required: boolean
  review_status: string
}
