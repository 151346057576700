import React, { useEffect, useState, useRef, useCallback } from 'react'
import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import XHRUpload from '@uppy/xhr-upload'
import { UploadButtonWrapper } from './UploadButton.styles'
import { baseApiURL } from '../../utils/UrlHelper'
import { type UseFormRegister } from 'react-hook-form'
import { type ReactHookFormValues } from 'Form/types'
import { type UppyFile } from 'types'
import { storage } from 'utils/storage'

export interface UploadButtonProps {
  text: string
  fieldName: string
  register: UseFormRegister<ReactHookFormValues>
  setValue: (name: string, value: string) => void
  onSuccess: (fileIdentifier: string) => void
  onError: (error: Error) => void
  onSelectFile: () => void
}

interface UploadResponse {
  identifier: string
}
export const UploadButton = ({
  text = 'Upload file',
  fieldName,
  register,
  setValue,
  onSuccess = (_fileIdentifier) => {
    throw Error('Not Implemented')
  },
  onError = (_error) => {
    throw Error('Not Implemented')
  },
  onSelectFile = () => {
    throw Error('Not Implemented')
  },
  ...rest
}: UploadButtonProps): JSX.Element => {
  const uploadFieldId = `${fieldName
    .replaceAll('[', '_')
    .replaceAll(']', '_')
    .replaceAll('.', '')}_upload`

  const [buttonText, setButtonText] = useState(text)
  const [uppy, setUppy] = useState<Uppy | null>(null)

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setButtonText(text)
  }, [text])

  useEffect(() => {
    const apiURL = baseApiURL()
    const uppy = new Uppy({
      id: 'uppy',
      debug: false,
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 5242880
      }
    })
      .use(XHRUpload, {
        endpoint: `${apiURL}/api/v1/files?identifier=${storage.get('identifier') ?? ''}`,
        formData: true,
        fieldName: 'file'
      })
      .use(FileInput, {
        id: 'FileInput',
        target: `#${uploadFieldId}`,
        pretty: true,
        inputName: 'files[]'
      })

    uppy.on('restriction-failed', (_, error) => {
      onError(error)
    })

    uppy.on('error', (error) => {
      onError(error)
    })

    uppy.on('upload-success', (file: File, response) => {
      const body = response.body as UploadResponse
      const fileIdentifier = body.identifier
      setValue(fieldName, file?.name ?? '')
      onSuccess(fileIdentifier)
    })

    uppy.on('file-added', (file: File) => {
      setButtonText('loading...')
      onSelectFile()
      uppy
        .upload()
        .then((result) => {
          if (result.successful.length > 0) {
            setButtonText(file.name)
          } else {
            const files = uppy.getFiles() as UppyFile[]
            if (files.length > 0) {
              uppy.removeFile(files[0].id)
            }
            setButtonText(text)
          }

          if (result.failed.length > 0) {
            result.failed.forEach((file) => {
              console.error(file.error)
            })
          }
        })
        .catch((error: Error) => {
          onError(error)
        })
    })
    setUppy(uppy)

    return () => {
      uppy.close()
    }
  }, [])

  const uploadFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files ?? [])
      if (uppy == null) {
        return
      }
      files.forEach((file) => {
        const files = uppy.getFiles() as UppyFile[]
        if (files.length > 0) {
          uppy.removeFile(files[0].id)
        }

        try {
          uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file
          })
        } catch (err) {
          onError(err as Error)
        }
      })
    },
    [uppy]
  )

  const handleClick = (): void => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click()
    }
  }

  return (
    <>
      <UploadButtonWrapper onClick={handleClick} {...rest}>
        {buttonText}
      </UploadButtonWrapper>
      <input
        id={uploadFieldId}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        type="file"
        onChange={(event) => {
          uploadFile(event)
        }}
        data-testid={fieldName}
        aria-label="file-upload"
      />
    </>
  )
}
