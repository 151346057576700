import { appendPathToURL, reactPlatformURL } from 'utils'
import { storage } from 'utils/storage'

/**
 * Returns the current deal identifier from the URL params.
 * If no identifier is found, it will attempt to set it from the localStorage.
 * If still no identifier is found, it will redirect to the sign-in page.
 *
 * @returns { identifier: string | null }
 * @example const { identifier } = useDealIdentifier()
 */
export const useDealIdentifier = (): string | null => {
  const params = new URLSearchParams(window.location.search)
  const urlIdentifier = params.get('identifier')

  if (urlIdentifier !== null) {
    storage.set('identifier', urlIdentifier)
    return urlIdentifier
  }

  const storedId = storage.get('identifier')

  if (storedId !== null) {
    return storedId
  }

  window.location.assign(appendPathToURL(reactPlatformURL(), '/signin'))

  return null
}
