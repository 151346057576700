import { type UseQueryResult, useQuery } from '@tanstack/react-query'

import { type Tenant } from 'types'
import { getTenant } from 'services/tenants'
import { tenantPrefix } from 'utils'

export const useTenant = (tenant?: string): UseQueryResult<Tenant, unknown> => {
  const tenantToken = tenant ?? tenantPrefix()
  return useQuery<Tenant>({
    queryKey: makeTenantQueryKey(tenantToken),
    queryFn: async (): Promise<Tenant> => await getTenant(tenantToken),
    enabled: tenantToken !== undefined
  })
}

export const makeTenantQueryKey = (tenant?: string): Array<string | undefined> => ['tenant', tenant]
