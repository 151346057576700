import React, { useEffect, useState, useContext } from 'react'
import { UploadButton } from '@baseComponents/UploadButton/UploadButton'
import { RadioUploadWrapper, ButtonWrapper } from './RadioUpload.styles'
import { setItem } from '../../services/cache'
import SectionContext from '../../Form/Context/Section.context'
import { ErrorMessage } from '@hookform/error-message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

export const RadioUpload = ({
  field,
  fieldName,
  register,
  setValue,
  getValues,
  errors,
  renderLabel,
  clearErrors
}) => {
  const [showUploadLaterButton, setShowUploadLaterButton] = useState(true)
  const [radioButtonChecked, setRadioButtonChecked] = useState('')
  const [uploadButtonText, setUploadButtonText] = useState('Upload file')
  const { dealId } = useContext(SectionContext)

  const { name } = register(fieldName, { required: field.required })

  const fieldKey = () => {
    return fieldName.split('.').pop()
  }

  const documentsKey = () => {
    const keyParts = fieldName.split('.')
    keyParts.pop()
    const documentsKey = keyParts.join('.')
    return `${documentsKey}.documents`
  }

  const appendDocument = async (prefixKey, index, fieldName, fileIdentifier) => {
    setValue(`${prefixKey}[${index}].file_key`, fileIdentifier)
    setValue(`${prefixKey}[${index}].name`, fieldName)
    await setItem(`${dealId}_form`, JSON.stringify(getValues()))
  }

  useEffect(() => {
    const fieldValue = getValues(fieldName)
    if (fieldValue && fieldValue !== 'later') {
      setShowUploadLaterButton(false)
      setUploadButtonText(fieldValue)
      setValue(fieldName, fieldValue)
    }
    setRadioButtonChecked(fieldValue)
  }, [uploadButtonText])

  const handleFileUploaded = async (fileIdentifier) => {
    setRadioButtonChecked(fileIdentifier)

    const prefixKey = documentsKey()

    const documents = getValues(prefixKey) ?? []
    const field = fieldKey()
    let documentIndex = documents.findIndex((document) => document.name === field)
    if (documentIndex === -1) {
      documentIndex = documents.length
    }

    await appendDocument(prefixKey, documentIndex, field, fileIdentifier)
    clearErrors(fieldName)
  }

  const handleSelectCheckbox = () => {
    setRadioButtonChecked('later')
    setValue(fieldName, 'later')
    clearErrors(fieldName)
  }

  return (
    <RadioUploadWrapper>
      <div>
        {renderLabel(field)}
        {field.required && <span className="required-indicator">*</span>}
      </div>
      <div className="fields">
        <div className="upload_option">
          <UploadButton
            setValue={setValue}
            register={register}
            fieldName={fieldName}
            name={name}
            text={uploadButtonText}
            onSuccess={async (fileIdentifier) => {
              await handleFileUploaded(fileIdentifier)
            }}
            onError={(_error) => {
              setRadioButtonChecked('')
              setShowUploadLaterButton(true)
            }}
            onSelectFile={() => {
              setShowUploadLaterButton(false)
            }}
          />
        </div>
        <div className="radio_option" style={{ display: showUploadLaterButton ? 'block' : 'none' }}>
          <input type="radio" checked={radioButtonChecked === 'later'} readOnly />

          <ButtonWrapper id="later" onClick={() => handleSelectCheckbox()}>
            <div>I will do it later</div>
          </ButtonWrapper>
        </div>
      </div>
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <div>
            <FontAwesomeIcon icon={faCircleExclamation} color={'#d93025'} />
            <span className={'form-row-error'}>Field is required</span>
          </div>
        )}
      />
    </RadioUploadWrapper>
  )
}
