import { Container } from '@mantine/core'

export const AppContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Container
      size="lg"
      sx={(theme) => ({
        marginBottom: theme.spacing.xl,
        marginTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        paddingTop: theme.spacing.xl,
        width: '100%'
      })}
    >
      {children}
    </Container>
  )
}
