import { type UseQueryResult, useQuery } from '@tanstack/react-query'

import { type Customization } from 'types'
import { fetchCustomization } from 'services/customizations'
import { tenantPrefix } from 'utils'

export const useTenantCustomization = (tenant?: string): UseQueryResult<Customization, unknown> => {
  const tenantToken = tenant ?? tenantPrefix()

  return useQuery<Customization>({
    queryKey: makeTenantCustomizationQueryKey(tenantToken),
    queryFn: async (): Promise<Customization> => await fetchCustomization(tenantToken),
    enabled: tenantToken !== undefined
  })
}

export const makeTenantCustomizationQueryKey = (
  tenant?: string
): Array<string | undefined | boolean> => ['customization', tenant]
