import { baseApiURL } from 'utils/UrlHelper'
import { storage } from 'utils/storage'

export const getForm = async (dealId: string): Promise<FormResponse> => {
  const fetchErrorMessage = 'Unable to fetch form'
  try {
    const apiUrl = `${baseApiURL()}/api/v1/forms?identifier=${dealId}`
    return await fetch(apiUrl).then((response) => {
      if (!response.ok) {
        throw new Error(fetchErrorMessage)
      }

      storage.set('identifier', dealId)
      return response.json() as unknown as FormResponse
    })
  } catch (_error) {
    throw new Error(fetchErrorMessage)
  }
}

export interface FormResponse {
  disclaimer: DisclaimerResponse
  id: number
  name: string
  sections: SectionResponse[]
  tenant_id: number
}

export interface DisclaimerResponse {
  agree_required: boolean
  body_html: TrustedHTML | string | null
  details: {
    body_html: TrustedHTML | string | null
    title_text: string | null
    trigger_text: string | null
  }
}

export interface SectionResponse {
  documents: DocumentResponse[]
  name: string
  steps: StepResponse[]
}

export interface DocumentResponse {
  name: string
  required: boolean
  review_status: string
}

export interface StepResponse {
  documents: DocumentResponse[]
  groups: GroupResponse[]
  name: string
  repeatable: boolean
  repeatable_min: number | null
  subtitle: string | null
  tooltip: string | null
}

export interface GroupResponse {
  id: number
  fields: FieldResponse[]
  model: string
  name: string
  order_index: number
  repeatable: boolean
  repeatable_button_label: string | null
  repeatable_max: number | null
  repeatable_min: number | null
  show_group_name: boolean
  show_repeatable_counter: boolean
}

export interface FieldResponse {
  default_value: string | null
  dynamic: boolean
  field_label: string
  field_name: string
  field_type:
    | 'checkbox'
    | 'currency'
    | 'date'
    | 'dropdown'
    | 'ein'
    | 'email'
    | 'file'
    | 'number'
    | 'percentage'
    | 'radio'
    | 'radio_upload'
    | 'select'
    | 'ssn'
    | 'tel'
    | 'text'
    | 'textarea'
  following_question: FollowingFieldResponse | null
  id: number
  is_following_question: boolean
  mask: string | null
  model: string | null
  min: number | null
  max: number | null
  options: FieldOptionResponse[]
  placeholder: string | null
  repeatable_index: number
  required: boolean
  show_borrower_side: boolean
  show_lender_side: boolean
  tooltip: string | null
}

export interface FollowingFieldResponse
  extends Omit<
    FieldResponse,
    'following_question' | 'min' | 'max' | 'repeatable_index' | 'show_borrower_side'
  > {}
export interface FieldOptionResponse {
  follow_up_fields: FollowUpFieldResponse[]
  is_file: boolean
  is_follow_up_option: boolean
  label: string
  value: string
}

export interface FollowUpFieldResponse
  extends Omit<
    FieldResponse,
    | 'following_question'
    | 'options'
    | 'repeatable_index'
    | 'show_borrower_side'
    | 'show_lender_side'
  > {
  options: FollowUpFieldOptionResponse[]
  order_index: number | null
}

export interface FollowUpFieldOptionResponse
  extends Omit<FieldOptionResponse, 'follow_up_fields' | 'is_file' | 'is_follow_up_option'> {}

export const initialFormValues: FormResponse = {
  disclaimer: {
    agree_required: false,
    body_html: null,
    details: {
      body_html: null,
      title_text: null,
      trigger_text: null
    }
  },
  id: 0,
  name: '',
  sections: [],
  tenant_id: 0
}
