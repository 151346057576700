import { useController, type Control } from 'react-hook-form'
import { Textarea } from '@mantine/core'
import { useId } from '@mantine/hooks'
import type { Field, ReactHookFormValues } from 'Form/types'

interface TextAreaProps {
  setValue: <T extends ReactHookFormValues>(field: string, value: T[string]) => void
  field: Field
  fieldName: string
  control: Control
  renderLabel: (arg: Field) => JSX.Element
}
export const TextareaInput = ({
  field,
  fieldName,
  control,
  renderLabel,
  setValue
}: TextAreaProps): JSX.Element => {
  const {
    field: { name, ref, value }
  } = useController<ReactHookFormValues>({
    name: fieldName,
    control
  })

  const id = useId()

  return (
    <Textarea
      size="md"
      id={id}
      onChange={(event) => {
        setValue(name, event.currentTarget.value)
      }}
      label={renderLabel(field)}
      withAsterisk={field.required}
      placeholder={field.placeholder}
      name={name}
      value={value}
      ref={ref}
      data-testid={field.field_label}
    />
  )
}
