import React from 'react'

import { usePlaidLink } from 'react-plaid-link'

import { useBankConnectionCreate } from 'hooks/useBankConnectionCreate'
import { useQuery } from '@tanstack/react-query'
import { Button } from '@mantine/core'
import { createLinkToken } from 'services/bank'
import { type LinkTokenResponse } from 'types'

interface ConnectButtonProps {
  disabled?: boolean
  identifier: string
  dealId: number
  onConnect: () => void
  onError: (error: string, displayError?: string) => void
}

const PLAID_ERROR =
  'There was a problem connecting your account through Plaid.' +
  ' Please check your details and try again.'

export const ConnectButton: React.FC<ConnectButtonProps> = ({
  disabled = false,
  identifier,
  dealId,
  onConnect,
  onError
}) => {
  const createLinkTokenFn = async (): Promise<LinkTokenResponse | null> => {
    try {
      return await createLinkToken(identifier, dealId)
    } catch (error) {
      const displayMessage =
        'An error occured starting the connection process. Please try again later.'
      onError((error as Error).message, displayMessage)
      return await Promise.resolve(null)
    }
  }

  const { data: token, isLoading: tokenLoading } = useQuery({
    queryKey: ['link_token', identifier, dealId],
    queryFn: createLinkTokenFn,
    enabled: identifier !== null && identifier !== '' && dealId !== null && !disabled
  })

  const {
    createConnection,
    error: connectionError,
    loading: connectionLoading,
    result
  } = useBankConnectionCreate(identifier, dealId)

  if (connectionError !== null) {
    const displayMessage = 'An error occured saving your account info. Please try again later.'
    onError(connectionError.message, displayMessage)
  }

  if (result !== null) {
    onConnect()
  }

  const onPlaidSuccess = (publicToken: string | undefined): void => {
    if (publicToken === undefined) {
      onError('Plaid did not return a public token', PLAID_ERROR)
      return
    }

    void createConnection(publicToken)
  }
  const linkToken: string | null = token?.link_token ?? null

  const {
    open: openPlaid,
    ready: plaidReady,
    error: plaidError
  } = usePlaidLink({
    token: linkToken,
    onSuccess: onPlaidSuccess
  })

  if (plaidError != null) {
    onError(plaidError.message, PLAID_ERROR)
  }

  const isLoading = connectionLoading || tokenLoading || !plaidReady

  const handleClick = (): void => {
    openPlaid()
  }

  return (
    <Button disabled={disabled} loading={isLoading} onClick={handleClick}>
      {!disabled ? 'Connect Your Bank Account' : 'Connected'}
    </Button>
  )
}
