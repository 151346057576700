import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useDealIdentifier } from './useDealIdentifier'
import { getUser } from 'services/users'
import { type User } from 'types'

export const useUser = (): UseQueryResult<User> => {
  const identifierToken = useDealIdentifier() ?? ''

  return useQuery<User, unknown>({
    queryKey: makeURLQueryKey(identifierToken),
    queryFn: async () => await getUser(identifierToken),
    enabled: identifierToken !== ''
  })
}

export const makeURLQueryKey = (identifierToken?: string): Array<string | undefined> => [
  'user',
  identifierToken
]
