import { useState, useEffect } from 'react'
import { useSignatureRequestURL } from 'hooks/useSignatureRequestURL'
import HelloSign from 'hellosign-embedded'

const env = import.meta.env.MODE
const signContainerId = 'sign-container'

const HelloSignContainer = ({ sGid }: { sGid: string }): JSX.Element => {
  const [signUrl, setSignUrl] = useState('')
  const [clientId, setClientId] = useState('')
  const {
    data: signatureRequestUrldata,
    error: signatureRequestURLError,
    isError: isSignatureRequestURLError
  } = useSignatureRequestURL(sGid)

  if (isSignatureRequestURLError) {
    console.error(signatureRequestURLError)
  }

  useEffect(() => {
    if (signatureRequestUrldata !== undefined) {
      const { signUrl: signURLResponse, clientId: clientIdResponse } = signatureRequestUrldata
      setSignUrl(signURLResponse)
      setClientId(clientIdResponse)
    }
  }, [signatureRequestUrldata])

  useEffect(() => {
    if (document.getElementById(`sign-container`) === null) {
      console.error('No sign container found')
    } else if (signUrl !== '' && clientId !== '') {
      const client = new HelloSign({
        clientId
      })

      client.open(signUrl, {
        allowCancel: false,
        skipDomainVerification: env === 'staging' || env === 'development',
        container: document.getElementById(signContainerId) as HTMLElement
      })
    }
  }, [signUrl, clientId])

  return (
    <div
      id={signContainerId}
      style={{ width: '100%', height: '80vh' }}
      data-testid="hellosign-container"
    />
  )
}

export default HelloSignContainer
