import { Fragment } from 'react'
import { Box, Space, Table, Text, Title } from '@mantine/core'
import pluralize from 'pluralize'

const Field = ({ field, fieldName }) => {
  return (
    <>
      <td width="50%">
        <Text weight={700}>{fieldName}</Text>
      </td>
      <td width="50%">
        <Text>{field.value}</Text>
      </td>
    </>
  )
}

export const Group = ({ group, groupName }) => {
  const repeatable = Array.isArray(group)
  return (
    <Box key={`${groupName}`}>
      {repeatable &&
        group.map((repeatableGroup, index) => {
          return (
            <RepeatableGroup
              repeatableGroup={repeatableGroup}
              groupName={groupName}
              index={index}
              key={`${groupName}${index}`}
            />
          )
        })}

      {!repeatable && <NonRepeatableGroup group={group} groupName={groupName} />}
    </Box>
  )
}

const RepeatableGroup = ({ repeatableGroup, groupName, index }) => {
  const groupLabel = `${pluralize.singular(groupName)} ${index + 1}`

  return (
    <>
      <Title order={4}>{groupLabel}</Title>
      <Space h="sm" />
      <Table striped highlightOnHover>
        <tbody>
          {Object.keys(repeatableGroup).map((key) => {
            return (
              <Fragment key={`${key}${index}`}>
                <tr>
                  <Field field={repeatableGroup[key]} fieldName={key} />
                </tr>
                {repeatableGroup[key].is_follow_up_option &&
                  repeatableGroup[key].subfields.map((field, index) => {
                    return (
                      <tr key={`subfield_${index}`}>
                        <Field field={field} fieldName={field.label} />
                      </tr>
                    )
                  })}
              </Fragment>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

const NonRepeatableGroup = ({ group, groupName }) => {
  return (
    <>
      <Title order={4}>{groupName}</Title>
      <Space h="sm" />
      <Table striped highlightOnHover>
        <tbody>
          {Object.keys(group).map((key) => {
            return (
              <Fragment key={key}>
                <tr key={key}>
                  <Field field={group[key]} fieldName={key} />
                </tr>
                {group[key].subfields.map((field, index) => {
                  return (
                    <tr key={`subfield_${index}`}>
                      <Field field={field} fieldName={field.label} />
                    </tr>
                  )
                })}
              </Fragment>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}
