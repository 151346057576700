import { createContext, useContext } from 'react'
import type { Tenant } from 'types'

interface Provider {
  children: React.ReactNode
  tenant: Tenant
}

const TenantContext = createContext<Tenant | null>(null)

export const TenantProvider = ({ children, tenant }: Provider): React.ReactElement => {
  return <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>
}

export const useTenantContext = (): Tenant => {
  const context = useContext(TenantContext)

  if (context === null) {
    throw new Error('useTenantContext must be used within a TenantProvider')
  }

  return context
}
