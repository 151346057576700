import { notifications } from '@mantine/notifications'

export const showError = (message: string): void => {
  notifications.show({
    title: 'Error',
    message,
    color: 'red',
    autoClose: false
  })
}

export const showSuccess = (message: string): void => {
  notifications.show({
    title: 'Success!',
    message,
    color: 'green',
    autoClose: 10000
  })
}
