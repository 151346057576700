import { type IDocument } from '../types'

interface DocumentProps {
  document: IDocument
}

export const Document = ({ document }: DocumentProps): JSX.Element => {
  const id = `${document.name.replace(/[\W]+/g, '-')}-file`

  return (
    <>
      <label htmlFor={id}>{document.name}</label>
      <input type="file" role="file-input" id={id} />
      <button>send later</button>
    </>
  )
}
