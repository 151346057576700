import React from 'react'

import { useBankConnectionDelete } from 'hooks/useBankConnectionDelete'
import { Button } from '@mantine/core'

interface DisconnectButtonProps {
  identifier: string
  dealId: number
  onDisconnect: () => void
  onError: (error: string, displayError?: string) => void
}

export const DisconnectButton: React.FC<DisconnectButtonProps> = ({
  identifier,
  dealId,
  onDisconnect,
  onError
}) => {
  const { deleted, deleteConnection, error, loading } = useBankConnectionDelete(identifier, dealId)

  if (error !== null) {
    const displayError =
      'An error occurred when diconnecting your account. ' + 'Please try again later.'
    onError(error.message, displayError)
  }

  if (deleted) {
    onDisconnect()
  }

  const handleClick = (): void => {
    void deleteConnection()
  }

  return (
    <Button color="red" loading={loading} onClick={handleClick}>
      Disconnect Bank Account
    </Button>
  )
}
