import React, { useState, useEffect } from 'react'
import { RadioWrapper, OptionWrapper } from './Radio.styles'
import { ErrorMessage } from '@hookform/error-message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

export const Radio = ({
  field,
  fieldName,
  register,
  setValue,
  buttonWidth,
  errors,
  clearErrors,
  getValues,
  renderLabel
}) => {
  const [radioButtonChecked, setRadioButtonChecked] = useState('')

  const { onChange, name, ref } = register(fieldName, { required: field.required })

  useEffect(() => {
    const fieldValue = getValues(fieldName)
    if (fieldValue !== null) {
      setRadioButtonChecked(fieldValue)
    }
  }, [])

  const handleSelectOption = (value) => {
    setRadioButtonChecked(value)
    setValue(fieldName, value)
    clearErrors(fieldName)
  }

  return (
    <RadioWrapper buttonWidth={buttonWidth}>
      <div>
        {renderLabel(field)}
        {field.required && <span className="required-indicator">*</span>}
      </div>
      <div className="fields">
        {field.options.map((option) => {
          return (
            <div className="radio_option" key={option.label}>
              <input
                type="radio"
                ref={ref}
                name={name}
                value={option.value}
                checked={radioButtonChecked === option.value}
                onChange={onChange}
              />

              <OptionWrapper onClick={() => handleSelectOption(option.value)}>
                {option.label}
              </OptionWrapper>
            </div>
          )
        })}
      </div>
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <div>
            <FontAwesomeIcon icon={faCircleExclamation} color={'#d93025'} />
            <span className={'form-row-error'}>Field is required</span>
          </div>
        )}
      />
    </RadioWrapper>
  )
}
