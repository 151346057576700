import styled from 'styled-components'

export const StyledButton = styled.button`
  width: 100%;
  background-color: #fff;
  padding: 9px 16px;
  border: 1px solid #cfd1d2;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  transition: border 0.2s ease-in-out;
  white-space: nowrap;

  &:hover {
    border-color: #393f44;
  }
`

export const StyledStep = styled.div`
  h2 {
    display: flex;
    align-items: center;
  }
`
