import { datadogRum } from '@datadog/browser-rum'
import type { Tenant, User } from 'types'

export function startMonitoring(applicationId: string, env: string, version: string): void {
  console.log('[Datadog] Datadog RUM client initialized')

  datadogRum.init({
    applicationId,
    clientToken: 'pub34bc849dc31b94d5844f6145d9af7623',
    site: 'us5.datadoghq.com',
    service: 'underwrite_react',
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
}

export function addTenantToMonitoringContext(tenant: Tenant): void {
  datadogRum.setGlobalContext({
    tenant: {
      id: tenant.id,
      name: tenant.organizationName,
      token: tenant.token
    }
  })
}

export function addUserToMonitoringContext(user: User): void {
  datadogRum.setUser({
    email: user.emailAddress,
    id: String(user.id),
    is_admin: user.isAdmin,
    is_operations: user.isOperations,
    is_sales_person: user.isSalesPerson,
    is_underwriter: user.isUnderwriter,
    name:
      user.firstName !== null
        ? [user.firstName?.trim(), user.lastName?.trim()].join(' ').trim()
        : user.emailAddress,
    phone_number: user.phoneNumber,
    title: user.title,
    user_type: user.userType
  })
}

export function removeUserFromMonitoringContext(): void {
  datadogRum.clearUser()
}
