import React, { useEffect, useState } from 'react'
import { Section } from './Section/Section'
import { DisclaimerProvider } from './Context/Disclaimer.context'
import { SectionProvider } from './Context/Section.context'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container } from '@mantine/core'
import { formatField } from '@formatters'
import { setItem, getItem } from '@services/cache'
import { getDeal } from '@services/deals'
import { getForm, initialFormValues } from '@services/forms'
import { useQuery } from '@tanstack/react-query'
import { SkeletonForm } from './SkeletonForm'
import pluralize from 'pluralize'

export const UnderwriteForm = () => {
  const [sessionToken, setSessionToken] = useState(null)
  const [instance, setInstance] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const useQueryParam = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQueryParam()
  const dealId = query.get('identifier')

  const {
    data: formData,
    error: formError,
    isFetching: isFormLoading,
    isError: isFormError
  } = useQuery({
    queryKey: ['form', dealId],
    queryFn: () => getForm(dealId),
    enabled: dealId !== null,
    initialValues: () => initialFormValues
  })

  const { disclaimer, sections } = formData ?? initialFormValues

  if (isFormError) {
    console.log(formError)
  }

  const {
    data: savedInstance,
    error: dealsError,
    isError: isDealsError
  } = useQuery({
    queryKey: ['deal', dealId],
    queryFn: () => getDeal(dealId)
  })

  if (isDealsError) {
    console.log(dealsError)
  }

  useEffect(() => {
    const initializeForm = async (dealInstance) => {
      if (!dealInstance) return
      const key = `${dealInstance.deal.id}_form`
      setSessionToken(key)
      const savedForm = await getItem(key)

      if (savedForm && savedForm.deal) {
        if (dealInstance.deal.updated_at === savedForm.deal.updated_at) {
          setInstance({ deal: savedForm.deal })
        }
      } else {
        const item = JSON.stringify({ deal: [dealInstance.deal] })
        await setItem(key, item)
      }
      setInstance({ deal: [dealInstance.deal] })
      setIsLoading(false)
    }

    initializeForm(savedInstance)
  }, [savedInstance])

  const buildReviewSection = async () => {
    const response = await getItem(sessionToken)
    const initialDealValues = response.deal[0]

    const getFieldValue = (group, field) => {
      return group.model === 'deal'
        ? initialDealValues[field.field_name]
        : initialDealValues[`${pluralize.plural(group.model)}`]?.[0]?.[field.field_name]
    }

    const setupFieldValue = (field, value, group) => {
      const response = { value: value, is_follow_up_option: false, subfields: [] }
      field.options.forEach((option) => {
        if (option.is_follow_up_option) {
          response.is_follow_up_option = true
          option.follow_up_fields.forEach((followUpField) => {
            const followUpValue = getFieldValue(group, followUpField)
            if (followUpValue === '') {
              return
            }
            response.subfields.push({
              label: followUpField.field_label,
              value: followUpValue ? formatField(followUpField, followUpValue) : ''
            })
          })
        }
      })

      return response
    }

    const dealValues = {}
    sections.forEach((section) => {
      const sectionValues = {}
      section.steps.forEach((step) => {
        const stepValues = {}
        step.groups.forEach((group) => {
          const groupValues = group.repeatable ? [] : {}
          if (!group.repeatable) {
            group.fields.forEach((field) => {
              const value = getFieldValue(group, field)
              groupValues[field.field_label] = setupFieldValue(
                field,
                value ? formatField(field, value) : '',
                group
              )
            })
          } else {
            initialDealValues[`${pluralize.plural(group.model)}`]?.forEach((model, _index) => {
              const modelValues = {}
              group.fields.forEach((field) => {
                const value = model[field.field_name]
                modelValues[field.field_label] = setupFieldValue(
                  field,
                  value ? formatField(field, value) : '',
                  group
                )
              })
              groupValues.push(modelValues)
            })
          }
          stepValues[group.name] = groupValues
        })
        sectionValues[step.name] = stepValues
      })
      dealValues[section.name] = sectionValues
    })

    return dealValues
  }

  return isLoading ?? isFormLoading ? (
    <SkeletonForm />
  ) : (
    <Container size="lg" data-testid="underwrite-form">
      <DisclaimerProvider disclaimer={disclaimer}>
        {sections?.map((section, index) => {
          return (
            /*
             * Temporary solution with Context Provider as a way to have a state of the section
             * Each Section has its own context
             * */
            <SectionProvider key={index}>
              <Section
                sectionIndex={index}
                section={section}
                formInstance={instance}
                buildReviewSection={buildReviewSection}
              />
            </SectionProvider>
          )
        })}
      </DisclaimerProvider>
    </Container>
  )
}
