import {
  ActionIcon,
  Button,
  Container,
  Group as MantineGroup,
  LoadingOverlay,
  Space,
  Stepper,
  Table,
  Text,
  Title,
  Stack
} from '@mantine/core'

import { StyledStep } from './SectionNav.styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Group } from './Group'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

export const Step = ({ step, stepName, setStep, stepNumber }) => {
  return (
    <StyledStep>
      <Title order={2} span>
        {stepName}
        <ActionIcon onClick={() => setStep(stepNumber)}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </ActionIcon>
      </Title>

      <Space h="sm" />
      <Stack spacing="md">
        {Object.keys(step).map((key) => {
          return <Group group={step[key]} groupName={key} key={key} />
        })}
      </Stack>
    </StyledStep>
  )
}
