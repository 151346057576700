import { QueryClient } from '@tanstack/react-query'

const fiveMinutes = 1000 * 60 * 5

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: fiveMinutes,
      refetchOnWindowFocus: false,
      retry: false,
      suspense: true
    }
  }
})
