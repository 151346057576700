import { Error } from 'components/error'
import { AppContainer } from 'components/app-container'

export const AppError: React.FC<{ message?: string }> = ({ message }) => {
  return (
    <AppContainer>
      <Error message={message} />
    </AppContainer>
  )
}
