import { storage } from 'utils/storage'
import { basePlatformApiURL } from 'utils/UrlHelper'
import { tenantPrefix } from 'utils'
import { type ChangePassword } from 'types'

export const resetPassword = async ({
  userEmail,
  randPassword,
  newPassword
}: {
  userEmail: string
  randPassword: string
  newPassword: string
}): Promise<ChangePassword> => {
  const identifier = storage.get('identifier')
  if (identifier == null) {
    throw Error('Error resetting password getting identifier from storage')
  }
  const fetchErrorMessage = 'Error resetting password'
  try {
    const headers = {
      'Content-Type': 'application/json',
      'session-token': identifier,
      'tenant-token': tenantPrefix()
    }
    return await fetch(`${basePlatformApiURL()}/users/reset_password`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({
        email_address: userEmail,
        rand_password: randPassword,
        new_password: newPassword
      })
    }).then(async (response) => {
      if (response.status === 401) {
        const responseJSON = (await response.json()) as ChangePassword
        const error = new Error(responseJSON.message)
        error.name = 'WrongPassword'
        throw error
      } else if (!response.ok) {
        const error = new Error(fetchErrorMessage)
        error.name = 'ResetPasswordError'
        throw error
      }
      return (await response.json()) as ChangePassword
    })
  } catch (error) {
    // If the error is a WrongPassword error, re-throw it so that the modal can display the correct error message
    if (error instanceof Error && error.name === 'WrongPassword') {
      throw error
    }
    // Otherwise, throw a generic reset password error message
    const newError = new Error(fetchErrorMessage)
    newError.name = 'ResetPasswordError'
    throw error
  }
}
