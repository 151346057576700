import React from 'react'

import { Container, Flex, Loader, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { useQuery } from '@tanstack/react-query'

import { useFeaturesContext } from 'providers'
import { type AppAccessResponse, getBankConnectionStatus } from 'services/bank'
import { type DealResponse, getDeal } from 'services/deals'
import { useDealIdentifier } from 'hooks/useDealIdentifier'

import { ConnectButton } from './ConnectButton'
import { DisconnectButton } from './DisconnectButton'
import { showError, showSuccess } from 'utils/notifications'

export const BankConnect: React.FC = () => {
  const identifier = useDealIdentifier()

  const handleError = (newError: string, displayError?: string): void => {
    console.warn('BankConnect Error: ', newError)

    showError(displayError ?? newError)
  }

  const handleSuccess = (message: string): void => {
    showSuccess(message)
  }

  const dealQuery = (): null | Promise<DealResponse> => {
    if (identifier === null) return null

    return getDeal(identifier)
  }

  const {
    data: dealData,
    error: dealError,
    isError: hasDealError,
    isLoading: dealLoading
  } = useQuery({
    queryKey: ['deal', identifier],
    queryFn: dealQuery,
    enabled: identifier !== null && identifier !== ''
  })

  if (hasDealError) {
    handleError((dealError as Error).message)
  }

  const businessName = dealData?.deal?.business_name ?? '...loading'
  const dealId = dealData?.deal?.id ?? null

  const connectionQuery = (): null | Promise<AppAccessResponse> => {
    if (identifier === null || dealId === null) return null

    return getBankConnectionStatus(identifier, dealId)
  }

  const {
    data: connectionData,
    error: connectionError,
    isError: hasConnectionError,
    isLoading: loading,
    refetch: refetchConnection
  } = useQuery({
    queryKey: ['connection', identifier, dealId],
    queryFn: connectionQuery,
    enabled: dealId !== null
  })

  if (hasConnectionError) {
    handleError((connectionError as Error).message)
  }

  const connected = connectionData?.[0]?.entity_id === String(dealId)

  const handleConnect = (): void => {
    void refetchConnection()
    notifications.clean()
    handleSuccess("You've successfully connected your accounts.")
  }

  const handleDisconnect = (): void => {
    void refetchConnection()
    notifications.clean()
    handleSuccess("You've successfully disconnected your accounts.")
  }

  const { banking_integration_client_disconnect: allowDisconnect } = useFeaturesContext()

  const BankConnectButton: React.FC = () => {
    if (loading || dealLoading) {
      return <Loader data-testid={'loading-indicator'} />
    }

    if (connected && allowDisconnect) {
      return (
        <DisconnectButton
          identifier={useDealIdentifier() ?? ''}
          dealId={dealId ?? 0}
          onDisconnect={handleDisconnect}
          onError={handleError}
        />
      )
    }

    return (
      <ConnectButton
        identifier={useDealIdentifier() ?? ''}
        dealId={dealId ?? 0}
        onConnect={handleConnect}
        onError={handleError}
        disabled={connected}
      />
    )
  }

  return (
    <Container fluid={true} style={{ minHeight: '60vh' }}>
      <Flex mih={50} gap="md" justify="center" align="center" direction="column">
        <h1 data-testid="business-name">{businessName}</h1>
        <Text>Connect your bank through a secure, encrypted channel</Text>
        <BankConnectButton />
      </Flex>
    </Container>
  )
}
