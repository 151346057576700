import { createStyles } from '@mantine/core'

export const HEADER_HEIGHT = 62

export const useStyles = createStyles((theme, { headerBackgroundColor, headerColor }) => ({
  root: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: headerBackgroundColor,
    borderBottomColor: '#7f7f7f',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px'
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none'
    }
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '62px',
    color: headerColor
  },

  img: {
    maxHeight: '38px',
    maxWidth: '300px'
  },

  logo: {
    paddingTop: '15px',
    height: '80px'
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    }
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none'
    }
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md
    }
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color
    }
  },

  emailHeader: {
    color: headerColor,
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    fontSize: '0.9em'
  },

  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '1rem',
    alignItems: 'center',
    marginLeft: 'auto',
    padding: '0 10px',
    position: 'relative',
    minWidth: '400px'
  }
}))
