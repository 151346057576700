import { type HexValue } from 'types'

export const DEFAULT_TENANT_URL = 'https://xenplatforms.com'

export const DEFAULT_LOGO_PATH = '/fv_logo.png'

// FastAR Funding colors
export const DEFAULT_COLORS: Record<string, HexValue> = {
  TEAL: '#1F6A96',
  BLUE: '#012D6A',
  WHITE: '#FFFFFF'
}
