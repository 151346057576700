import { basePlatformApiURL } from 'utils/UrlHelper'
import { type AppAccess, type LinkTokenResponse } from 'types'
import { tenantPrefix } from 'utils'

const ENTITY_TYPE = 'deal'
const BANK_INTEGRATION_URL = `${basePlatformApiURL()}/integration/plaid`

export type AppAccessResponse = AppAccess[]

/**
 * Creates a new bank connection integration for the given deal.
 *
 * @param {string} identifier   - The session id
 * @param {number} dealId       - The deal id
 * @param {string} publicToken  - The public token generated by createLinkToken
 *
 * @returns {AppAccessResponse} - The application access object
 *
 * @throws {Error}              - An error message
 */
export const createBankConnection = async (
  identifier: string,
  dealId: number,
  publicToken: string
): Promise<AppAccess> => {
  const fetchErrorMessage = 'Failed to create bank connection.'
  try {
    const name = `account_for_${ENTITY_TYPE}_${dealId}`
    const url = `${BANK_INTEGRATION_URL}/application_accesses`
    const headers = {
      'Content-Type': 'application/json',
      'session-token': identifier,
      'tenant-token': tenantPrefix()
    }
    const body = JSON.stringify({
      entity_type: ENTITY_TYPE,
      entity_id: String(dealId),
      institution_id: 'None', // TODO: Replace with actual institution: https://finvoice-inc.atlassian.net/browse/FP-6698
      institution_name: 'None',
      public_token: publicToken,
      name
    })

    return await fetch(url, { method: 'POST', headers, body }).then((response) => {
      if (!response.ok) {
        throw new Error(fetchErrorMessage)
      }

      return response.json() as unknown as AppAccess
    })
  } catch (_error) {
    throw new Error(fetchErrorMessage)
  }
}

/**
 * Creates a link token for the bank connection integration.
 * This is a prerequisite for starting the Plaid Link flow.
 *
 * @param {string} identifier   - The session id
 * @param {number} dealId       - The deal id associated with the bank connection.
 * @returns {LinkTokenResponse} - The token, expiration, and request id
 * @throws {Error}              - An error message
 * @see https://plaid.com/docs/#create-link-token
 */
export const createLinkToken = async (
  identifier: string,
  dealId: number
): Promise<LinkTokenResponse> => {
  const fetchErrorMessage = 'Failed to create link token.'
  try {
    const url = `${BANK_INTEGRATION_URL}/application_accesses/create_link_token`
    const headers = {
      'Content-Type': 'application/json',
      'session-token': identifier,
      'tenant-token': tenantPrefix()
    }
    const body = JSON.stringify({
      entity_type: ENTITY_TYPE,
      entity_id: String(dealId)
    })

    return await fetch(url, { method: 'POST', headers, body }).then((response) => {
      if (!response.ok) {
        throw new Error(fetchErrorMessage)
      }

      return response.json() as unknown as LinkTokenResponse
    })
  } catch (_error) {
    throw new Error(fetchErrorMessage)
  }
}

/**
 * Deletes a bank connection integration for the given deal.
 *
 * @param {string} identifier - The session id
 * @param {number} dealId     - The deal id associated with the bank connection
 *
 * @returns {boolean}         - True if successful
 *
 * @throws {Error}
 */
export const deleteBankConnection = async (
  identifier: string,
  dealId: number
): Promise<boolean> => {
  const fetchErrorMessage = 'Failed to delete bank connection.'
  try {
    const url = `${BANK_INTEGRATION_URL}/application_accesses/disconnect`
    const headers = {
      'Content-Type': 'application/json',
      'session-token': identifier,
      'tenant-token': tenantPrefix()
    }
    const body = JSON.stringify({
      entity_type: ENTITY_TYPE,
      entity_id: String(dealId),
      institution_id: 'None' // TODO: Replace with actual institution: https://finvoice-inc.atlassian.net/browse/FP-6698
    })

    return await fetch(url, { method: 'DELETE', headers, body }).then((response) => {
      if (!response.ok) {
        throw new Error(fetchErrorMessage)
      }

      return true
    })
  } catch (_error) {
    throw new Error(fetchErrorMessage)
  }
}

/**
 * Fetches the application status for the bank connection integration.
 *
 * @param {string} identifier     - The session id
 * @param {number} dealId         - The deal id associated with the bank connection
 *                                  connection.
 * @returns {AppAccessResponse[]} - An array including the Application Access
 *                                  object
 * @throws {Error}                - An error message
 */
export const getBankConnectionStatus = async (
  identifier: string,
  dealId: number
): Promise<AppAccessResponse> => {
  const fetchErrorMessage = 'Failed to get bank connection status.'
  try {
    const entityParams = `?entity_type=${ENTITY_TYPE}&entity_id=${dealId}`
    const url = `${BANK_INTEGRATION_URL}/application_accesses${entityParams}`

    return await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'session-token': identifier,
        'tenant-token': tenantPrefix()
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(fetchErrorMessage)
        }

        return response
      })
      .then((response) => response.json() as unknown as AppAccessResponse)
  } catch (_error) {
    throw new Error(fetchErrorMessage)
  }
}
